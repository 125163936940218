// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { functions } from "../../../firebase";
import { Row, Col } from "react-bootstrap";

// Components and Other Class Objects
import { convertGiveBeeIntToUSDString, convertGiveBeeIntToClientPoints } from "../../../helpers/PointsAndMoneyConversions";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";
import "../BusinessActivityHistory/BusinessActivityHistoryPage.css";

import giveBeeIcon from "../../../assets/images/icon-givebee.svg";
import platformsIcon from "../../../assets/images/platforms-icon.svg";
import coinIcon from "../../../assets/images/coin-icon.svg";
import contentIcon from "../../../assets/images/content-engagements-icon.svg";
import photoViewsIcon from "../../../assets/images/photo-views-icon.svg";
import websiteVisitsIcon from "../../../assets/images/website-visits-icon.svg";
import businessBoostIcon from "../../../assets/images/business-boost-icon.svg";

const BusinessAccountPerformance: React.FC<any> = (props:any) => {

  const { currentBusiness, setErrorMessage } = props;

  // Data for account performance
  const [socialSharesCount, setSocialSharesCount] = useState(0);
  const [socialSharesPoints, setSocialSharesPoints] = useState(0);
  const [socialSharesCharges, setSocialSharesCharges] = useState(0);

  const [rewardedPhotosCount, setRewardedPhotosCount] = useState(0);
  const [rewardedPhotosPoints, setRewardedPhotosPoints] = useState(0);
  const [rewardedPhotosCharges, setRewardedPhotosCharges] = useState(0);

  const [uniqPostViewsCount, setUniqPostViewsCount] = useState(0);
  const [uniqWebsiteVisitsCount, setUniqWebsiteVistsCount] = useState(0);
  
  useEffect(() => {
    if (currentBusiness) {
      queryThisMonthsChargeMetrics(currentBusiness.id);
    }
  }, []);

  /**
   * 
   * @param businessId business user id for the respective user
   * 
   * Will grab the counts for the charging metrics of this month
   */
  const queryThisMonthsChargeMetrics = async(businessId:string) => {
    try {
      var queryTheSocialCharges = functions.httpsCallable("queryThisMonthsSocialChargeMetrics");
      queryTheSocialCharges({ businessId })
        .then((result:any) => {
          const data = result.data;          
          // Social shares counts
          const socialSharesData = data.socialShares;
          let socialSharesCount:number = 0;
          for (const [key, val] of Object.entries(socialSharesData)) {
            socialSharesCount += (Number(val) ? Number(val) : 0);
          }
          setSocialSharesCount(socialSharesCount);
        })
        .catch((err:any) => {
          setErrorMessage(`Error occurred in trying to get social sharing charges: ${err.message}`);
        });
      
      var queryTheGiveBeeCharges = functions.httpsCallable("queryThisMonthsGiveBeeChargeMetrics");
      queryTheGiveBeeCharges({ businessId })
        .then((result:any) => {
          const data = result.data;
          const thePhotoViewers = data.photoViews ? data.photoViews : 0;
          const theWebsiteVisitors = data.websiteVisitors ? data.websiteVisitors : 0;
          const theBusinessBoosts = data.businessRewardedContent ? data.businessRewardedContent : 0;
          setUniqPostViewsCount(thePhotoViewers);
          setUniqWebsiteVistsCount(theWebsiteVisitors);
          setRewardedPhotosCount(theBusinessBoosts);
        })
        .catch((err:any) => {
          setErrorMessage(`Error occurred in trying to get GiveBee charges: ${err.message}`);
        });
    } catch(err) {
      setErrorMessage(`Error occurred in trying to get this month's charge metrics: ${err.message}`);
    }
  }

  return (
    <Row className="mt-3 mb-3">
      <Col xs={12} className="mainMetricsCol">
        <Row className="mt-3">
          <Col className="my-auto" xs={12} md={4}>
            <img className="activityTitleIcon mb-3" alt="" src={businessBoostIcon} />
            <h4 className="font-weight-demibold">Social Boosts</h4>
          </Col>
          <Col xs={12} md={4}>
            <img alt="" className="regularIcon" src={platformsIcon}></img>
            <h4 className="activityColTitle">Social Shares</h4>
            <p>{socialSharesCount} shares</p>
            <p> { convertGiveBeeIntToClientPoints(socialSharesPoints) } <img alt="" className="regularIcon mb-1" src={coinIcon} /></p>
            <p> { convertGiveBeeIntToUSDString(socialSharesCharges)}</p>
          </Col>
          <Col xs={12} md={4}>
            <img alt="" className="regularIcon" src={contentIcon}></img>
            <h4 className="activityColTitle">Rewarded Content</h4>
            <p>{rewardedPhotosCount} photos</p>
            <p>{convertGiveBeeIntToClientPoints(rewardedPhotosPoints)} <img alt="" className="regularIcon mb-1" src={coinIcon} /></p>
            <p>{convertGiveBeeIntToUSDString(rewardedPhotosCharges)}</p>
          </Col>
        </Row>
        <br/>
        <hr/>
        <br/>
        <Row>
          <Col className="my-auto" xs={12} md={4}>
            <img className="activityTitleIcon mb-3" alt="" src={giveBeeIcon} />
            <h4 className="font-weight-demibold">GiveBee Metrics</h4>
          </Col>
          <Col xs={12} md={4}>
            <img alt="" className="regularIcon regularIcon-eye" src={photoViewsIcon}></img>
            <h4 className="activityColTitle">Uniq. Post Views</h4>
            <p>{uniqPostViewsCount} views</p>
            <p>{convertGiveBeeIntToClientPoints(uniqPostViewsCount * (333 * 0.60))} <img alt="" className="regularIcon mb-1" src={coinIcon} /></p>
            <p>{convertGiveBeeIntToUSDString(uniqPostViewsCount * 333)}</p>
          </Col>
          <Col xs={12} md={4}>
            <img alt="" className="regularIcon" src={websiteVisitsIcon}></img>
            <h4 className="activityColTitle">Uniq. Website Visits</h4>
            <p>{uniqWebsiteVisitsCount} visits</p>
            <p>{convertGiveBeeIntToClientPoints(uniqPostViewsCount * (1333 * 0.60))} <img alt="" className="regularIcon mb-1" src={coinIcon} /></p>
            <p>{convertGiveBeeIntToUSDString(uniqPostViewsCount * (1333))}</p>
          </Col>
        </Row>
        <br/>
        <hr/>
        <br/>
        <Row>
          <Col xs={12}>
            <h4 className="text-left font-weight-demibold">Total: {convertGiveBeeIntToUSDString(uniqPostViewsCount * 333 + uniqWebsiteVisitsCount * 1333 + socialSharesPoints + rewardedPhotosPoints )}</h4>
          </Col>
        </Row>
        <br/>
        {/* TODO: Save for later
        <Row className="mt-3">
          <Col className="text-right">
            <a className="button-link" href="/business/account/paymentMethods/"><p>Payment Methods</p></a>
          </Col>
        </Row> */}
      </Col>
    </Row>
  );
};

export default BusinessAccountPerformance;