import { db, firebaseAuth, googleProvider } from '../firebase';

export function signUpWithEmail(email) {
	return firebaseAuth().signInWithEmailLink(email);
}

export function signUpWithEmailAndPassword(email, password) {
	return firebaseAuth().createUserWithEmailAndPassword(email, password);
}

export function loginWithEmailAndPassword(email, password) {
	return firebaseAuth().signInWithEmailAndPassword(email, password);
}

export function loginWithGoogle() {
	return firebaseAuth().signInWithRedirect(googleProvider);
}

export function logout() {
	return firebaseAuth().signOut();
}

export async function getUserTypeInfo(id) {
	const businessDoc = await db.collection("businesses").doc(id).get();
	if (businessDoc.exists) {
		return "existingBusinessUser";
	}
	const userDoc = await db.collection("users").doc(id).get();
	if (userDoc.exists) {
		return "existingUser";
	}

	return "userDoesNotExist";
}