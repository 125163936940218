export function convertUSDDecimalToClientPoints(usd:number) {
  return usd * 100;
}

export function convertUSDDecimalToGiveBeeInt(usd:number) {
  return usd * 10000;
}

export function convertGiveBeeIntToUSDString(giveBeeInt:number) {
  const smallerDecimal:number = giveBeeInt / 10000;
  const roundedNum = smallerDecimal.toFixed(2);
  return `$${roundedNum}`
}

export function convertGiveBeeIntToUSDDecimal(giveBeeInt:number) {
  return (giveBeeInt / 10000).toFixed(2);
}

export function convertGiveBeeIntToClientPoints(giveBeeInt:number) {
  const smallerDecimal:number = giveBeeInt / 100;
  const roundedNum = Math.floor(smallerDecimal);
  return roundedNum;
}