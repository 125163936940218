import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

interface PaymentActivationProps extends RouteComponentProps {}

const PaymentActivation: React.FC<PaymentActivationProps|any> = ({ currentBusiness }) => {

  const [howPointsWork, expandHowPointsWork] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Stripe Publishable Keys
  // Test: pk_test_cqMRCqhaSHPQDR6qogFMP26V
  // Live: pk_live_s7uHy1fX6RoMdM1GdeLOPLgC
  const stripePromise = loadStripe("pk_live_s7uHy1fX6RoMdM1GdeLOPLgC")

  return (
      <div>
        <Row className="account-page-title-row">
          <Col className="text-left">
            <h1>Payment & Activation</h1>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <Row>
              <Col className="text-left">
                <h5 className="font-weight-demibold">How do payments work?</h5>
                <p>
                  We charge <strong className="font-weight-bold">$0.01 per point</strong> generated by rewarded content or sponsored customers.
                  You are charged for these points at the end of these month. Live points and expected charges can be tracked anytime on your dashboard.
                </p>
                <Row>
                  <Col>
                    <h5 className="text-left font-weight-demibold">How do customers earn points?</h5>
                  </Col>
                  <Col className="text-left">
                    <button
                      className="button-link button-readMore"
                      onClick= {(e:any) => expandHowPointsWork(!howPointsWork)}
                    >
                      { howPointsWork ? "Hide" : "Read More" }
                    </button>
                  </Col>
                </Row>
                { howPointsWork &&
                <Row>
                  <Col>
                    <h5>Automatic Points</h5>
                    <p>
                      Sponsored customers can earn points automatically for each view and 
                      website visit their photos generate. Customers only become sponsored when you choose
                      to do so from your dashboard.
                    </p>
                    <ul>
                      <li className="text-left">
                        <i></i>
                        <strong className="font-weight-bold">2 points ($0.02)</strong> per unique photo view
                      </li>
                      <li className="text-left">
                        <i></i>
                        <strong className="font-weight-bold">8 points ($0.08)</strong> per unique website visit
                      </li>
                    </ul>
                    <h5>Manual Points</h5>
                    <p>
                      You can choose to reward content and social shares from your dashboard.
                      When you send these rewards, you choose the amount you wish to pay and reward.
                    </p>
                    <ul>
                      <li className="text-left">
                        <i></i>
                        <strong className="font-weight-bold">10-500 points ($0.10 - $5.00)</strong> per share
                      </li>
                      <li className="text-left">
                        <i></i>
                        <strong className="font-weight-bold">100-500 points ($1 - $5.00)</strong> per rewarded piece of content
                      </li>
                    </ul>
                  </Col>
                </Row>
                }
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col>
                <h3>Questions?</h3>
                <p>Text Us: (847) 856-9342</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Elements stripe={stripePromise}>
              <CheckoutForm currentBusiness={currentBusiness} />
            </Elements>
          </Col>
        </Row>
      </div>
  );
};

export default PaymentActivation;