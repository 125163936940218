// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

// Components and Other Class Objects
import BusinessActivityRows from "./BusinessActivityRows";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";
import "./BusinessActivityHistoryPage.css";

const BusinessAccountHistoryPage: React.FC<any> = (props:any) => {

  const { currentBusiness } = props;

  const [errorMessage, setErrorMessage] = useState("");
  
  useEffect(() => {
    // Nada
  }, []);

  return (
    <Row>
      <Col>
        <Row className="row-center">
          <Col>
            <Row className="account-page-title-row">
              <Col className="text-left">
                <h1>Activity History</h1>
                <hr/>
              </Col>
            </Row>
            <BusinessActivityRows currentBusiness={ currentBusiness } setErrorMessage={ setErrorMessage } />
            <Row hidden={ errorMessage === "" }>
              <Col>
                <h3>Error occurred:</h3>
                <p>{`${errorMessage}`}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BusinessAccountHistoryPage;