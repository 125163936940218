// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { firebaseAuth, db } from "../../firebase";
import { logout } from "../../helpers/auth";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col, Nav } from "react-bootstrap";

// Components and Other Class Objects
import { Business } from "../../constants/ClassObjects";
import { currentOnboardStatus } from "./BusinessOnboarding/OnboardingFunctions";

import OnboardingNavMenu from "./BusinessOnboarding/OnboardingNavMenu";
// import PaymentPage from "./BusinessOnboarding/PaymentActivation";
import SetBusinessDetails from "./BusinessOnboarding/SetBusinessDetails";
import SetRewards from "./BusinessOnboarding/SetRewards";

import BusinessAccountNavMenu from "./BusinessAccountNavMenu";
import BusinessDashboard from "./BusinessDashboard/BusinessDashboardPage";
import BusinessCustomers from "./BusinessCustomers/BusinessCustomersPage";
import BusinessContent from "./BusinessContent/BusinessContentPage";
import BusinessRewards from "./BusinessRewards/BusinessRewardsPage";
import BusinessActivityHistory from "./BusinessActivityHistory/BusinessActivityHistoryPage";
import BusinessAccountSettings from "./BusinessAccountSettings/BusinessAccountSettingsPage";

import * as pathNames from "../../constants/PathNames";

// UI and CSS
import logo from "../../assets/images/GiveBee_201.png";
import "../businessAuth/BusinessSignUp.css";
import "./BusinessAccountPage.css";
import PaymentActivation from "./BusinessOnboarding/PaymentActivation";

interface BusinessAccountProps extends RouteComponentProps {}

const BusinessAccountPage: React.FC<BusinessAccountProps> = ({ history, location }) => {

    const [currentBusiness, setCurrentBusiness] = useState<Business|undefined>(undefined);
    const [onboardingComplete, setOnboardingComplete] = useState(false);
  
    useEffect(() => {
        // Call and int business user
        firebaseAuth().onAuthStateChanged(user => {
            if (user) {
                db.collection("businesses")
                    .doc(user.uid)
                    .onSnapshot((businessDoc) => {
                        if (businessDoc.exists) {
                            const businessUser = new Business(businessDoc.id, businessDoc.data());
                            setCurrentBusiness(businessUser);
                            updatePathBasedOnAccountStatus(businessUser);
                        } else {
                            // Not a business user
                            logout();
                            history.push("/");
                        }
            });
        } else {
          history.push("/");
        }
      });
    }, []);

    function updatePathBasedOnAccountStatus(businessUser:Business) {

        const { moreInfoSet, rewardsSet, bothSubscriptionsActive } = currentOnboardStatus(businessUser);
        const completeAccount = moreInfoSet && rewardsSet && bothSubscriptionsActive;
        setOnboardingComplete(completeAccount);

        if (completeAccount || onboardingComplete) {
            // If business is authenthenticated, finished onboarding go to customers dash
            // OR If business is authenticated, finished onboarding, and typed some random
            // business/account/ or business/onboarding/ path, send them to customers dash
            if ((pathNames.validBusinessOnboardingPaths.includes(location.pathname))
                || !(pathNames.validBusinessAcountPaths.includes(location.pathname))) {
                // Pust to the first page of a live account
                history.push(pathNames.businessDashboardPath);
            }
        } else {
            // Business is missing essential info,
            // Automatically navigate to proper component
            if (!moreInfoSet) {
                history.push(pathNames.businessOnboardDetailsPath);
            }
            else if (!rewardsSet) {
                history.push(pathNames.businessOnboardRewardsPath);
            }
            else if (!bothSubscriptionsActive) {
                history.push(pathNames.businessOnboardSubscribePath);
            }   
        }
    }
  
    return (
        <div>
            <header className="app-header">
                <div className="layout-header layout-header-group">
                    <Row>
                        <Col>
                            <img
                            alt="GiveBee-logo"
                            className="logo"
                            src={logo}
                            onClick={() => { history.push("/"); } }
                            />
                        </Col>
                    </Row>
                </div>
            </header>
            <Row className="backgroundDiv row-center">
                <Nav>
                    { currentBusiness && !onboardingComplete && <OnboardingNavMenu history={history} location={location} currentBusiness={currentBusiness} /> }
                    { currentBusiness && onboardingComplete && <BusinessAccountNavMenu history={history} location={location} currentBusiness={currentBusiness} /> }
                </Nav>
                <Col className="topCol">
                    {/* Business Onboarding Flags*/}
                    { currentBusiness && (!onboardingComplete && location.pathname === pathNames.businessOnboardDetailsPath) && <SetBusinessDetails currentBusiness={currentBusiness} /> }
                    { currentBusiness && (!onboardingComplete && location.pathname === pathNames.businessOnboardRewardsPath) && <SetRewards currentBusiness={currentBusiness} /> }
                    { currentBusiness && (!onboardingComplete && location.pathname === pathNames.businessOnboardSubscribePath) && <PaymentActivation currentBusiness={currentBusiness} /> }
                    {/* Onboarded Business Account Pages */}
                    { currentBusiness && (onboardingComplete && location.pathname === pathNames.businessDashboardPath) && <BusinessDashboard currentBusiness={currentBusiness} /> }
                    { currentBusiness && (onboardingComplete && location.pathname === pathNames.businessCustomersPath) && <BusinessCustomers currentBusiness={currentBusiness} /> }
                    { currentBusiness && (onboardingComplete && location.pathname === pathNames.businessContentPath) && <BusinessContent currentBusiness={currentBusiness} /> }
                    { currentBusiness && (onboardingComplete && location.pathname === pathNames.businessManageRewardsPath) && <BusinessRewards currentBusiness={currentBusiness} /> }
                    { currentBusiness && (onboardingComplete && location.pathname === pathNames.businessActivityPath) && <BusinessActivityHistory currentBusiness={currentBusiness} /> }
                    { currentBusiness && (onboardingComplete && location.pathname === pathNames.businessEditAccountPath) && <BusinessAccountSettings currentBusiness={currentBusiness} /> }
                </Col>
            </Row>
        </div>
    );
  };

export default BusinessAccountPage;