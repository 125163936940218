// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { functions } from "../../../firebase";
import { Row, Col, Spinner } from "react-bootstrap";
import { LazyImage } from "../../../helpers/LazyImage";
import { PhotoMetric } from "../../../constants/PointsClassObjects";
import { convertGiveBeeIntToUSDDecimal } from "../../../helpers/PointsAndMoneyConversions";

// UI CSS
import coinIcon from "../../../assets/images/coin-icon.svg";
import xIcon from "../../../assets/images/X-icon-dark.svg";

const RewardContentModal: React.FC<any> = (props:any) => {

  const {
    contentArray,
    photoToReward,
    setPhotoToReward,
  } = props;

  const [pointsAmount, setPointsAmount] = useState(50);
  const [sendingPoints, setSendingPoints] = useState(false);
  const [sentPointsSuccess, setSentPointsSuccess] = useState(false);
  const [sendingPointsErr, setSendingPointsError] = useState("");

  useEffect(() => {
    console.log(`In the useEffect for the Modal!`);
    if (photoToReward !== undefined) {
      setModal();
    }
  }, []);

  const photoUserName = (photo:PhotoMetric) => {
    const firstName = (photo.userInfo.firstName ? photo.userInfo.firstName : "");
    return (firstName.length > 1) ? firstName : "your customer";
  }

  /**
   * 
   * @param photo respective photoMetric doc that is about to receive reward points
   * 
   * Triggered when the business fills out the amount to reward and triggers the sending of points.
   */
  const triggerSendingOfPoints = () => {
    if (pointsAmount) {
      setSendingPoints(true);
      const photoRewardData = {
        ...photoToReward,
        pointsAmount,
        chargeAmount: convertGiveBeeIntToUSDDecimal(pointsAmount * 100) ,
        currency: 'usd',
      };
      var businessRewardedPhoto = functions.httpsCallable("businessRewardedPhoto");
      businessRewardedPhoto(photoRewardData)
        .then((result) => {
          const resultData = result.data;
          contentArray.forEach((currPhoto:PhotoMetric) => {
            if (photoToReward.id === currPhoto.id) {
              currPhoto.business_rewarded_photo = resultData.business_rewarded_photo;
              currPhoto.creatorSharedTo = resultData.creatorSharedTo;
              currPhoto.totalPhotoViews = resultData.totalPhotoViews;
              currPhoto.totalWebsiteVisits = resultData.totalWebsiteVisits;
              currPhoto.totalCharge = resultData.totalCharge;
            }
          });
          setSendingPoints(false);
          setSentPointsSuccess(true);
        })
        .catch((err) => {
          setSendingPointsError(err.message);
          setSendingPoints(false);
        });
    }
  }

  function setModal() {
    const element = document.getElementById("rewardOverlay");
    if (element !== null) {
      element.style.display = "block";
    }
  }

  return (
    <Row className="rewardContentModal">
      <Row>
        <Col className="text-right">
          <button
            className="button-icon mr-3"
            onClick={() => setPhotoToReward(undefined) }
          >
            <img className="regularIcon ml-3 mt-3" alt="exit" src={ xIcon } />
          </button>
        </Col>
      </Row>
      <Col>
        <LazyImage
          className="rewardContentImage"
          alt="photo was deleted :("
          src={photoToReward.url}
        />
        <Row>
          <Col>
            <h4 className="mt-2">Reward this photo</h4>
            <p>Want to save this photo or repost it somewhere?<br/>Thank {photoUserName(photoToReward)} by sending them some points!</p>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} md={6}>
            <div className="form-group sendPointsField">
              <label className="font-weight-demibold">Points Amount</label>
              <input
                type="range"
                min="50"
                max="500"
                step="10"
                className="slider"
                onChange={(event) => setPointsAmount(Number(event.target.value))}
              />
              <label className="font-weight-demibold texr-right">{pointsAmount} <img className="regularIcon mb-1" src={coinIcon}/></label>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="form-group sendPointsField">
              <label className="font-weight-demibold">Cost ($ USD)</label>
              <input
                type="text"
                pattern="[0-9]*"
                className="form-control field-rewards"
                id="amount"
                name="amount"
                placeholder="1.00"
                disabled={true}
                value={ (pointsAmount) ? convertGiveBeeIntToUSDDecimal(pointsAmount * 100) : '' }
              />
              <p className="sendPointsSubText mt-1">This purchase will be added to this month's invoice</p>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="mainPointsButtonsDiv" hidden={ sentPointsSuccess }>
              <button
                className="button-link mr-3"
                onClick={() => { setPhotoToReward(undefined) }}
              >
                Cancel
              </button>
              <button
                className="ml-3"
                disabled={!pointsAmount || !(pointsAmount > 0)}
                onClick={ () => triggerSendingOfPoints() }
              >
                <div>Send points</div>
              </button>
            </div>
            <div className="donePointsButtonsDiv" hidden={ !sentPointsSuccess }>
              <p className="doneDiv font-weight-demibold mr-2 ">
                Hooray! Points sent successfully! &#x1F973;
              </p>
              <button
                className="doneDiv ml-2"
                onClick={ () => { setPhotoToReward(undefined); } }
              >
                <div>Done</div>
              </button>
            </div>
            <Spinner animation="border" className="mt-1" hidden={!sendingPoints}  />
            <p className="mt-2" hidden={sendingPointsErr === ""}>Error: {sendingPointsErr} </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}

export default RewardContentModal;