import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import * as pathNames from '../../../constants/PathNames';
import { currentOnboardStatus } from "./OnboardingFunctions";

import OnboardingProgressModule from "./OnboardingProgressModule";
import "../NavMenu.css";
import "./OnboardingNavMenu.css";

const OnboardingNavMenu: React.FC<any> = (props:any) => {

  const { history, location, currentBusiness } = props;

  const [moreInfoCompleted, setMoreInfoCompleted] = useState(false);
  const [rewardsStepCompleted, setRewardsAreCompleted] = useState(false);
  const [subscriptionsCreated, setSubscriptionsCreated] = useState(false);

  useEffect(() => {
    setNavMenuState(currentBusiness);
  }, [props.currentBusiness]);

  const setNavMenuState = (currentBusiness:any) => {
    if (currentBusiness) {
      const { moreInfoSet, rewardsSet, bothSubscriptionsActive } = currentOnboardStatus(currentBusiness);
      setMoreInfoCompleted(moreInfoSet);
      setRewardsAreCompleted(rewardsSet);
      setSubscriptionsCreated(bothSubscriptionsActive);
    }
  }

  const businessDetailsClassNames = () => {
    if (location.pathname === pathNames.businessOnboardDetailsPath) {
      return "onboard-step-link-selected " + ((moreInfoCompleted) ? "onboard-step-link-complete" : "onboard-step-link-incomplete");
    } else {
      return (moreInfoCompleted) ? "onboard-step-link-complete" : "onboard-step-link-incomplete";
    }
  }

  const setRewardsClassNames = () => {
    if (location.pathname === pathNames.businessOnboardRewardsPath) {
      return "onboard-step-link-selected " + ((rewardsStepCompleted) ? "onboard-step-link-complete" : "onboard-step-link-incomplete");
    } else {
      return (rewardsStepCompleted) ? "onboard-step-link-complete" : "onboard-step-link-incomplete";
    }
  }

  const choosePlanClassNames = () => {
    if (location.pathname === pathNames.businessOnboardSubscribePath) {
      return "onboard-step-link-selected " + ((subscriptionsCreated) ? "onboard-step-link-complete" : "onboard-step-link-incomplete");
    } else {
      return (subscriptionsCreated) ? "onboard-step-link-complete" : "onboard-step-link-incomplete";
    }
  }

  return (
    <div className="ml-2 pl-2 pr-2">
      <OnboardingProgressModule currentBusiness={currentBusiness} />
      <Row>
        <Col>
          <ol>
            <li className="font-weight-demibold text-left">
              <a className={businessDetailsClassNames()}
                href={pathNames.businessOnboardDetailsPath}
                onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessOnboardDetailsPath); }}>
                  Business details
              </a>
            </li>
            <li className="font-weight-demibold text-left mt-3">
              <a className={setRewardsClassNames()}
                href={pathNames.businessOnboardRewardsPath}
                onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessOnboardRewardsPath); }}>
                  Set rewards
              </a>
            </li>
            <li className="font-weight-demibold text-left mt-3">
              <a className={choosePlanClassNames()}
                href={pathNames.businessOnboardSubscribePath}
                onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessOnboardSubscribePath); } }>
                  Account Activation
              </a>
            </li>
          </ol>
        </Col>
      </Row>
    </div>
  );
}

export default OnboardingNavMenu;
