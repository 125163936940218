// React Components and Other Libraries
import * as GFK from "../../../global/firestoreKeys";
import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { Table, Row, Col } from "react-bootstrap";

// Components and Other Class Objects
import { BusinessVisitor } from "../../../constants/ClassObjects";
import { convertToTimeAgo } from "../../../helpers/TimeAndDateFunctions";
import { convertGiveBeeIntToUSDString, convertGiveBeeIntToClientPoints } from "../../../helpers/PointsAndMoneyConversions";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";

import beeIcon from "../../../assets/images/icon-givebee.svg";

const BusinessCustomersPage: React.FC<any> = (props:any) => {

  const { currentBusiness } = props;

  const [sponsorships, setBusinessSponsorships] = useState<Array<BusinessVisitor>>([]);
  const [sponsorshipsLoading, setSponsorshipsLoading] = useState(true);
  const [newSponsorshipPending, setNewSponsorshipPending] = useState(false);

  const [visitors, setBusinessVisitors] = useState<Array<BusinessVisitor>>([]);
  const [visitorsLoading, setBusinessVisitorsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  
  useEffect(() => {
    if (currentBusiness) {
      queryBusinessBalances(currentBusiness.id);
      querySponsorships(currentBusiness.id);
      queryBusinessVisitors(currentBusiness.id);
    }
  }, []);


  const queryBusinessBalances = async(businessId:string) => {
    try {
      const businessBalanceData = (await db.collection("businessBalances").doc(businessId).get()).data();
      if (!businessBalanceData) { return; }
    } catch (err) {
      setErrorMessage(`Error occurred in query for business balance data: ${err.message}`);
    }
  }

  /**
   * 
   * @param businessId businessId to get businessVisitors who are sponsored
   * 
   * This is a listener function to keep accurate count of sponsorships
   */
  const querySponsorships = (businessId:string) => {

    setSponsorshipsLoading(true);

    db.collection(GFK.FC.businessVisitors)
      .where(GFK.FF.businessId, "==", businessId)
      .where(GFK.FF.status, "==", "sponsored")
      .orderBy(GFK.FFCharges.currentPointsBalance, "desc")
      .onSnapshot((sponsorshipsSnapshot) => {
        const sponsoredDocs = sponsorshipsSnapshot.docs;
        if (sponsoredDocs.length > sponsorships.length) { setNewSponsorshipPending(false); }
        const sponsorArr = sponsoredDocs.map((doc) => new BusinessVisitor(doc.id, doc.data()));
        setBusinessSponsorships(sponsorArr);
        setSponsorshipsLoading(false);
      });
  }

  const queryBusinessVisitors = (businessId:string) => {
    
    setBusinessVisitorsLoading(true)
      
    db.collection(GFK.FC.businessVisitors)
      .where(GFK.FF.businessId, "==", businessId)
      .orderBy(GFK.FF.namesFirstName)
      .onSnapshot((visitorsSnapshot) => {
        const visitorArr = visitorsSnapshot.docs.map((doc) => new BusinessVisitor(doc.id, doc.data()));
        setBusinessVisitors(visitorArr);
        setBusinessVisitorsLoading(false);
      });
  }

  const SponsorshipsList = sponsorships.map((sponsoredVisitor:BusinessVisitor) =>
    { 
      return (
        sponsoredVisitor &&
        <tr key={`sponsoredVisitor-${sponsoredVisitor.id}`}>
          <td>{`${sponsoredVisitor.firstName} ${sponsoredVisitor.lastName}`}</td>
          <td>{`${convertGiveBeeIntToUSDString(sponsoredVisitor.totalTransactionMatchAmount)}`}</td>
          <td>{`${convertToTimeAgo(sponsoredVisitor.latest_transaction)}`}</td>
          <td>{`${sponsoredVisitor.photos}`}</td>
          <td>{`${convertToTimeAgo(sponsoredVisitor.latest_post)}`}</td>
          <td>{`${convertGiveBeeIntToClientPoints(sponsoredVisitor.currentPointsBalance) }`}</td>
        </tr> 
      );
    }
  );

  function getUserAndBusinessIds(visitorId:string) {
    const splicedIds = visitorId.split('_');
    const userId = splicedIds[0];
    const businessId = splicedIds[1];
    return { userId, businessId }
  }

  const createNewSponsorship = async (visitorId:string) => {
    setNewSponsorshipPending(true);
    try {
      const { userId, businessId } = getUserAndBusinessIds(visitorId);
      await db.collection(GFK.FC.sponsorships)
              .doc(visitorId)
              .set({ [GFK.FF.businessId]: businessId, [GFK.FF.userId]: userId }, { merge: true });
    } catch (err) {
      setNewSponsorshipPending(false);
      setErrorMessage(`Error in creating sponsorship: ${err.message}`);
    }
  }

  const VisitorList = visitors.map((visitor:BusinessVisitor) =>
    {
      return (
        visitor &&
        <tr key={`visitor-${visitor.id}`}>
          <td>{`${visitor.firstName} ${visitor.lastName}`}</td>
          <td>{`${visitor.photos}`}</td>
          <td>{`${convertToTimeAgo(visitor.latest_post)}`}</td>
          <td>
            { visitor.status === "sponsored"
            ? <p className="sponsoredLabel font-weight-demibold"><img className="icon" alt="" src={beeIcon} /> { " sponsored" }</p>
            : <button
                className="button-sponsorship"
                disabled={newSponsorshipPending}
                onClick={(e:any) => createNewSponsorship(visitor.id) }
              >
                { newSponsorshipPending ? "Pending..." : "Sponsor this visitor!" }
              </button>
            }
          </td>
        </tr>
      );
    }
  );

  return (
    <div>
      <Row className="row-center">
        <Col>
          <Row className="account-page-title-row">
            <Col className="text-left">
              <h1>Customers</h1>
              <hr/>
            </Col>
          </Row>
          <h3 className="text-left font-weight-demibold mb-3">Sponsored Loyalty Customers</h3>
          <Table
            hover
            hidden={visitorsLoading || visitors.length <= 0 }
            className="form mt-3" id="userTable"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Total Spent</th>
                <th>Last Visit</th>
                <th>Total Photos</th>
                <th>Last Post</th>
                <th>Points Balance</th>
              </tr>
            </thead>
            <tbody>
              {SponsorshipsList}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="row-center">
        <Col>
          <h3 className="text-left font-weight-demibold mt-3">All Customers</h3>
          <Table
            hover
            hidden={visitorsLoading || visitors.length <= 0 }
            className="form mt-3" id="userTable"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Total Photos</th>
                <th>Last Post</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {VisitorList}
            </tbody>
          </Table>                                
        </Col>
      </Row>
      <Row
        hidden={ visitorsLoading || visitors.length > 0 }
        id="noUsers"
        className="form mt-3"
      >
        <Col>
          <h3>No one yet.</h3>
          <p>Be sure to tell your customers to create content on their GiveBee profile and tag you!</p>
        </Col>
      </Row>
      <Row hidden={ errorMessage === "" }>
        <Col>
          <h3>Error occurred:</h3>
          <p>{`${errorMessage}`}</p>
        </Col>
      </Row>
    </div>        
  );
};

export default BusinessCustomersPage;