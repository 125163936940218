// React Components and Other Libraries
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { Row, Col } from "react-bootstrap";

// Components and Other Class Objects
import { PhotoMetric } from "../../../constants/PointsClassObjects";
import { LazyImage } from "../../../helpers/LazyImage";
import { convertToTimeAgo, getCurrentTimestamp } from "../../../helpers/TimeAndDateFunctions";
import { convertGiveBeeIntToUSDString } from "../../../helpers/PointsAndMoneyConversions";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";

import beeIcon from "../../../assets/images/icon-givebee.svg";
import coinIcon from "../../../assets/images/coin-icon.svg";
import gbSharedActive from "../../../assets/images/givebee-shared-active.svg";
import igActive from "../../../assets/images/ig-active.svg";
import igInactive from "../../../assets/images/ig-inactive.svg";
import igStoriesInactive from "../../../assets/images/igStories-inactive.svg";
import igStoriesActive from "../../../assets/images/igStories-active.svg";
import fbActive from "../../../assets/images/fb-active.svg";
import fbInactive from "../../../assets/images/fb-inactive.svg";
import fbStoriesInactive from "../../../assets/images/fbStories-inactive.svg";
import fbStoriesActive from "../../../assets/images/fbStories-active.svg";
import fbMessengerInActive from "../../../assets/images/fbMessenger-inactive.svg";
import fbMessengerActive from "../../../assets/images/fbMessenger-active.svg";

const BusinessContentRows: React.FC<any> = (props:any) => {

  const {
    contentArray,
    setPhotoToNudge,
    setPhotoToReward,
    setErrorMessage,
  } = props;

  const [ratingPhoto, setRatingPhoto] = useState(false);

  useEffect(() => {
    // Nada
  }, [props.contentArray]);
  
  const sharedToIcons = (sharedToTimestamps:any) => {
    const sharedTo = Object.keys(sharedToTimestamps);
    return (
      <div className="mt-1">
        <img className="social-icon" src={gbSharedActive} alt="" />
        <img className="social-icon ml-1" src={sharedTo.includes("ig_shared_at") ? igActive : igInactive } alt="" />
        <img className="social-icon ml-1" src={sharedTo.includes("igStories_shared_at") ? igStoriesActive : igStoriesInactive} alt="" />
        <img className="social-icon ml-1" src={sharedTo.includes("fb_shared_at") ? fbActive : fbInactive } alt="" />
        <img className="social-icon ml-1" src={sharedTo.includes("fbStories_shared_at") ? fbStoriesActive : fbStoriesInactive } alt="" />
        <img className="social-icon ml-1" src={sharedTo.includes("fbMessenger_shared_at") ? fbMessengerActive : fbMessengerInActive } alt="" />
      </div>
    );
  }

  /**
   * 
   * @param photo photo being written to
   * 
   * Business can request that a user share a certain photo.
   */
  const sendBusinessSocialShareRequest = (photo:PhotoMetric) => {
    // Will trigger requestOverlay and modal appearance in:
    // - BusinessDashboardPage.tsx
    // - BusinessContentPage.tsx
    setPhotoToNudge(photo);
  }

  /**
   * 
   * @param photo photo being written to
   * 
   * Business can reward and pay this user for their posted photo
   */
  const sendPhotoRewardPoints = (photo:PhotoMetric) => {
    // Will trigger rewardOverlay and modal appearance in:
    // - BusinessDashboardPage.tsx
    // - BusinessContentPage.tsx
    setPhotoToReward(photo);
  }

  /**
   * 
   * @param photo respective photoMetric doc being written to
   * 
   * Triggered when business user taps a like or dislike of photo
   */
  const rateThisPhoto = async(photo:PhotoMetric, rate:string) => {
    try {
      setRatingPhoto(true);
      // Write to DB
      const photoMetricRef = db.collection('photoMetrics').doc(photo.id);
      const currentTimestamp = getCurrentTimestamp();
      await photoMetricRef.set({ business_viewed_photo: currentTimestamp, businessRatedPhoto: rate }, { merge: true });
      // Flip for the client (BusinessDashboardPage does not use listener)
      contentArray.forEach((currPhoto:PhotoMetric) => {
        if (photo.id === currPhoto.id) {
          currPhoto.business_viewed_photo = currentTimestamp;
          currPhoto.businessRatedPhoto = rate;
        }
      });
      setRatingPhoto(false);
    } catch (err) {
      setErrorMessage(`Error in rating photo: ${photo.id}, ${err.message}`);
      setRatingPhoto(false);
    }
  }

  /**
   * 
   * @param photo
   * 
   * Appears once photo is rated and rewarded
   */
  const viewThePhoto = (photo:PhotoMetric) => {
    window.open(photo.url, "_blank");
  }

  const photoButton = (photo:PhotoMetric) => {
    return (
    <Row className="mt-3 mb-3">
      <Col>
        <div className="photoButtonDiv">
        {(photo.business_viewed_photo === 0 && photo.businessRatedPhoto === undefined) &&
          <div className="photoRateButtonsDiv">
            <button
              className="buttonPhotoRate buttonPhotoRate-up ml-1"
              disabled={ ratingPhoto }
              onClick={() => rateThisPhoto(photo, "positive") }
            >
              <div>&#128515; Yes!</div>
            </button>
            <button
              className="buttonPhotoRate buttonPhotoRate-down ml-1"
              disabled={ ratingPhoto }
              onClick={() => rateThisPhoto(photo, "negative") }
            >
              <div>&#128528; Meh.</div>
            </button>
          </div>
        }
        {(photo.business_viewed_photo > 0 && photo.businessRatedPhoto === "positive") &&
          <div className="photoRewardButtonDiv">
            <button
              className="photoButtonMain"
              disabled={ ratingPhoto }
              hidden={ (photo.business_rewarded_photo > 0) }
              onClick={() => sendPhotoRewardPoints(photo) }
            >
              <div>Reward Photo<img className="regularIcon mb-1 ml-1" alt="" src={ coinIcon } /> +</div>
            </button>
            <button
              className="photoButtonMain-viewPhoto"
              hidden={ !(photo.business_rewarded_photo > 0) }
              onClick={() => viewThePhoto(photo) }
            >
              <div>View Photo</div>
            </button>
          </div>
        }
        </div>
      </Col>
    </Row>
    );
  }

  const photoCol = (photo:PhotoMetric) => {
    return (
      <Row>
        <Col>
          <LazyImage
            className="businessContent"
            alt="photo was deleted :("
            src={(photo.photoDeleted) ? "" : photo.url}
          />
          { photoButton(photo) }
        </Col>
      </Row>
    );
  }

  const nudgeButton = (photo:PhotoMetric) => {
    return (
      <Row className="mt-3 mb-3">
        <Col>
          {(photo.creatorSharedTo || photo.businessShareRequests) &&
            <button
              className="button-link nudgeButtonLink"
              onClick={() => sendBusinessSocialShareRequest(photo) }
            >
              <div>Nudge Again</div>
            </button>
          }
          {(!photo.creatorSharedTo && !photo.businessShareRequests) &&
            <button
              className="photoButtonMain "
              onClick={() => sendBusinessSocialShareRequest(photo) }
            >
              <div>Request more shares!<img className="regularIcon mb-1 ml-1" alt="" src={ coinIcon } /> +</div>
            </button>
          }
      </Col>
    </Row>
    );
  }

  const photoInfoCol = (photo:PhotoMetric) => {
    return (
      <Col xs={12} md={6}>
        <Row>
          <Col>
            <p>
              <strong className="mr-2 font-weight-demibold">
                {`${photo.userInfo.firstName ? photo.userInfo.firstName : ""} ${photo.userInfo.lastName ? photo.userInfo.lastName: ""}`}
              </strong>
              <strong className="font-weight-demibold">
                {convertToTimeAgo(photo.created_at)}
              </strong>
            </p>
            { photo.sponsoredPost &&
              <p className="sponsoredLabel font-weight-demibold">
                <img className="icon" alt="" src={beeIcon} /> 
                { " sponsored" }
              </p>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              <p>shared to:</p>
              { sharedToIcons(photo.creatorSharedTo ? photo.creatorSharedTo : []) }
            </div>
            { nudgeButton(photo) }
          </Col>
        </Row>
      </Col>
    );
  }

  const metricsCol = (photo:PhotoMetric) => {
    return (
      <Col xs={12} md={6}>
        <Row className="mt-3">
          <Col className="text-left">
            <ul className="ul-contentMetrics">
              <li>
                <p>
                  <strong className="font-weight-demibold">
                    Post views:
                  </strong> {photo.totalPhotoViews}
                </p>
              </li>
              <li>
                <p>
                  <strong className="font-weight-demibold">
                    Website visits:
                  </strong> {photo.totalWebsiteVisits}
                </p>
              </li>
              {
                (photo.business_rewarded_photo > 0) &&
                <li>
                  <p>
                    <img className="regularIcon mb-1 mr-1" alt="" src={ coinIcon } />
                    <strong className="font-weight-demibold">
                      Rewarded Photo
                    </strong>
                  </p>
                </li>
              }
              {
                (photo.totalCharge > 0) &&
                <li>
                  <p>
                    <strong className="font-weight-demibold">
                      Total Charge:
                    </strong> { convertGiveBeeIntToUSDString(photo.totalCharge) }
                  </p>
              </li>
              }
            </ul>
          </Col>
        </Row>
      </Col>
    );
  }

  const PhotoRows = contentArray.map((photo:PhotoMetric, index:number) => {
      return (
        <div
          key={`img-${index}`}
          className="businessContentRow mt-3"
        >
          <Row>
            <Col xs={12} md={4} className="col-content">
              { photoCol(photo) }
            </Col>
            <Col xs={12} md={8} className="text-left">
              <Row className="mt-3">
                { photoInfoCol(photo) }
                { metricsCol(photo) }
              </Row>
            </Col>
          </Row>
          <hr />
        </div>
      );
  });

  return (
    <Row>
      <Col>
        { PhotoRows }
      </Col>
    </Row>      
  );
};

export default BusinessContentRows;