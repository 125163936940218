/**
 * Class object for any interactions that happen to a photo including:
 *  - totalPhotoViews, uniquePhotoViews
 *  - totalWebsiteVisits, uniqueWebsiteVisits
 *  - business_rewarded_photo
 */
export class PhotoMetric {
  // General data
  public id:string;
  public businessId:string;
  public businessName:string;
  public businessRatedPhoto?:string;
  public created_at:number;
  public photoDeleted:boolean;
  public sponsoredPost:boolean;
  public url:string;
  public userId:string;
  public userInfo:{[key:string]: any} = {};

  // Social Shares Data
  public businessShareRequests?:Object|undefined;
  public creatorSharedTo?:Object|undefined;

  // Business logic Timestamps
  public business_viewed_photo:number;
  public businessRequestTimestamps?:number[];
  public business_rewarded_photo:number;
  public latest_business_share_request?:number;
  public latest_metric:number;
  public latest_points_increment:number;
  public latest_post_view:number;
  public latest_website_visit:number;

  // Metrics numbers
  public totalPhotoViews:number;
  public totalWebsiteVisits:number;
  public uniquePhotoViews:number;
  public uniqueWebsiteVisits:number;

  // Business charge/payout numbers
  public totalPostViewCharge:number;
  public totalPostViewPayout:number;
  public totalPostViewPoints:number;

  public totalWebsiteVisitCharge:number;
  public totalWebsiteVisitPayout:number;
  public totalWebsiteVisitPoints:number;

  public totalSocialShareCharge:number;
  public totalSocialSharePayout:number;
  public totalSocialSharePoints:number;

  public photoRewardCharge:number;
  public photoRewardPayout:number;
  public photoRewardPoints:number;

  public totalCharge:number;
  public totalPayout:number;            // same as totalPointsGenerated
  public totalPointsGenerated:number;   // same as totalPayout

  constructor(id:string, data:any) {

    this.id = id;
    this.businessId = data.businessId ? data.businessId : '';
    this.businessName = data.businessName ? data.businessName : '';
    this.created_at = data.created_at ? data.created_at : 0;
    this.photoDeleted = data.photoDeleted ? data.photoDeleted : false;
    this.sponsoredPost = data.sponsoredPost ? data.sponsoredPost : (data.sponsored ? data.sponsored : false);
    this.url = data.url ? data.url : (data.photoUrl ? data.photoUrl : '');
    this.userId = data.userId ? data.userId : (data.posterUserId ? data.posterUserId : '');
    this.userInfo = data.userInfo ? data.userInfo : { firstName: '', lastName: '', profilePhotoUrl: '' };

    this.businessShareRequests = data.businessShareRequests;
    this.creatorSharedTo = data.creatorSharedTo;

    this.business_viewed_photo = data.business_viewed_photo ? data.business_viewed_photo : 0;
    this.businessRatedPhoto = data.businessRatedPhoto;
    this.businessRequestTimestamps = data.businessRequestTimestamps;
    this.business_rewarded_photo = data.business_rewarded_photo
                                  ? data.business_rewarded_photo
                                  : (data.business_reposted_photo ? data.business_reposted_photo : 0);
    this.latest_business_share_request = data.latest_business_share_request;
    this.latest_metric = data.latest_metric ? data.latest_metric : 0;
    this.latest_points_increment = data.latest_points_increment ? data.latest_points_increment : 0;
    this.latest_post_view = data.latest_post_view ? data.latest_post_view : 0;
    this.latest_website_visit = data.latest_website_visit ? data.latest_website_visit : 0;

    this.totalPhotoViews = data.totalPhotoViews ? data.totalPhotoViews : (data.totalPostViews ? data.totalPostViews : 0);
    this.totalWebsiteVisits = data.totalWebsiteVisits ? data.totalWebsiteVisits : 0;
    this.uniquePhotoViews = data.uniquePhotoViews ? data.uniquePhotoViews : (data.uniquePostViews ? data.uniquePostViews : 0);
    this.uniqueWebsiteVisits = data.uniqueWebsiteVisits ? data.uniqueWebsiteVisits : 0;

    this.totalPostViewCharge = data.totalPostViewCharge ? data.totalPostViewCharge : 0;
    this.totalPostViewPayout = data.totalPostViewPayout ? data.totalPostViewPayout : 0;
    this.totalPostViewPoints = data.totalPostViewPoints ? data.totalPostViewPoints : 0;

    this.totalWebsiteVisitCharge = data.totalWebsiteVisitCharge ? data.totalWebsiteVisitCharge : 0;
    this.totalWebsiteVisitPayout = data.totalWebsiteVisitPayout ? data.totalWebsiteVisitPayout : 0;
    this.totalWebsiteVisitPoints = data.totalWebsiteVisitPoints ? data.totalWebsiteVisitPoints : 0;

    this.totalSocialShareCharge = data.totalSocialShareCharge ? data.totalSocialShareCharge : 0;
    this.totalSocialSharePayout = data.totalSocialSharePayout ? data.totalSocialSharePayout : 0;
    this.totalSocialSharePoints = data.totalSocialSharePoints ? data.totalSocialSharePoints : 0;

    this.photoRewardCharge = data.photoRewardCharge
                              ? data.photoRewardCharge
                              : (data.photoRepostCharge ? data.photoRepostCharge : 0);
    this.photoRewardPayout = data.photoRewardPayout
                              ? data.photoRewardPayout
                              : (data.photoRepostPayout ? data.photoRepostPayout : 0);
    this.photoRewardPoints = data.photoRewardPoints
                              ? data.photoRewardPoints
                              : (data.photoRepostPoints ? data.photoRepostPoints : 0);

    this.totalCharge = data.totalCharge ? data.totalCharge : 0;
    this.totalPayout = data.totalPayout ? data.totalPayout : 0;
    this.totalPointsGenerated = data.totalPointsGenerated ? data.totalPointsGenerated : 0;
  }
}

/**
 * Class object for points transactions
 * Ex: User redeems a reward for a certain amount of points
 */
export class PointsTransaction {
  id:string;
  badgeImageUrl:string;
  businessId:string;
  businessName:string;
  created_at:number;
  firstName:string;
  lastName:string;
  pointsBalanceAfter:number;
  pointsBalanceBefore:number;
  pointsRedeemed:number;
  rewardChosen:string;
  rewards?:any[];
  type:string;
  userId:string;
  username:string;
  
  constructor(id:string, data:any) {
    this.id = id;
    this.badgeImageUrl = data.badgeImageUrl ? data.badgeImageUrl : "";
    this.businessId = data.businessId;
    this.businessName = data.businessName ? data.businessName : "";
    this.created_at = data.created_at;
    this.firstName = data.names
                  ? (data.names.firstName ? data.names.firstName : "")
                  : "";
    this.lastName = data.names
                  ? (data.names.lastName ? data.names.lastName : "")
                  : "";
    this.pointsBalanceAfter = data.pointsBalanceAfter ? data.pointsBalanceAfter : 0;
    this.pointsBalanceBefore = data.pointsBalanceBefore ? data.pointsBalanceBefore : 0;
    this.pointsRedeemed = data.pointsRedeemed ? data.pointsRedeemed : 0;
    this.rewardChosen = data.rewardChosen ? data.rewardChosen : "";
    this.rewards = data.rewards ? data.rewards : [];
    this.type = data.type ? data.type : "";
    this.userId = data.userId;
    this.username = data.names
                  ? (data.names.username ? data.names.username : "")
                  : "";
  }
}

/**
 * Class object for transaction matches found with sponsored users
 * Note: Although all data will be read through the query, not all of it is modeled here
 */
export class TransactionMatch {
  id:string;
  amount:number;
  businessId:string;
  created_at:number;
  date:string;
  firstName:string;
  lastName:string;
  merchantName:string;
  name:string;
  paymentChannel:string;
  pending:boolean;
  pointsGenerated:number;
  pointsPerUSD:number;
  userId:string;
  username:string;
  
  constructor(id:string, data:any) {
    this.id = id;
    this.amount = data.amount ? data.amount : 0.00;
    this.businessId = data.businessId;
    this.created_at = data.created_at;
    this.date = data.date;
    this.firstName = data.names
                    ? (data.names.firstName ? data.names.firstName : "")
                    : "";
    this.lastName =  data.names
                    ? (data.names.firstName ? data.names.firstName : "")
                    : "";
    this.merchantName = data.merchantName ? data.merchantName: "";
    this.name = data.name ? data.name : "";
    this.paymentChannel = data.paymentChannel ? data.paymentChannel : "";
    this.pending = data.pending ? data.pending : false;
    this.pointsGenerated = data.pointsGenerated ? data.pointsGenerated : 0;
    this.pointsPerUSD = data.pointsPerUSD ? data.pointsPerUSD : 0;
    this.userId = data.userId;
    this.username = data.names
                  ? (data.names.username ? data.names.username : "")
                  : "";
  }
}