import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

function getDEVConfig() {

  const firebaseDevConfig = {
    apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_DEV_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_DEV_FIREBASE_MESSAGE_SENDER_ID}`,
    appId: `${process.env.REACT_APP_DEV_FIREBASE_APP_ID}`
  };
  
  return firebaseDevConfig;
}

function getPRODConfig() {

  const firebaseProdConfig = {
    apiKey: `${process.env.REACT_APP_PROD_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_PROD_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_PROD_FIREBASE_MESSAGE_SENDER_ID}`,
    appId: `${process.env.REACT_APP_PROD_FIREBASE_APP_ID}`,
  };

  return firebaseProdConfig;
}

const config = (process.env.NODE_ENV=='production') ? getPRODConfig() : getDEVConfig();
if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

export const db = firebase.firestore();
export const functions = firebase.functions();
export const FieldValue = firebase.firestore.FieldValue;
export const increment = firebase.firestore.FieldValue.increment(1);
export const decrement = firebase.firestore.FieldValue.increment(-1);
export const firebaseAuth = firebase.auth;
export const storage = firebase.storage();

// --- Firebase Auth --- //
export const googleProvider = new firebase.auth.GoogleAuthProvider();