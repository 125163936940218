import React, { useState } from "react";
import styled from "styled-components";
import RightNav from "./RightNav";

const StyledBurger = styled.div<{ open: boolean }>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 20;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${ (props:any) => (props.open ? '#ccc' : '#333') };
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${ (props:any) => (props.open ? 'rotate(45deg)' : 'rotate(0)') };
    }

    &:nth-child(2) {
      transform: ${ (props:any )=> (props.open ? 'translateX(100%)' : 'translateX(0%)') };
      opacity: ${ (props:any) => (props.open ? 0 : 1) };
    }

    &:nth-child(3) {
      transform: ${ (props:any) => (props.open ? 'rotate(-45deg)' : 'rotate(0)') };
    }
  }
`;

const Burger = (props:any) => {

  const { history } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger open={ open } onClick={ () => { console.log('click!'); setOpen(!open); } }>
        <div/>
        <div />
        <div />
      </StyledBurger>
      <RightNav history={ history } open={ open } />
    </>
  )
}

export default Burger;