import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer: React.FC<any> = (props:any) => {

  const { history, open } = props;

  function getCurrentYear() {
    return new Date().getFullYear();
  }

  return (
    <Row className="px-3">
      <Col xs={12} md={6}>
        <p className="ml-3">&copy; { getCurrentYear() } GiveBee, LLC</p>
      </Col>
      <Col xs={12} md={6} className="centerContentY text-right">
        <p className="centerContentY mr-3"><a href="/legal/privacy" className="a-reg">Privacy</a></p>
        <p className="centerContentY mr-3"><a href="/legal/terms" className="a-reg">Terms</a></p>
        <p className="centerContentY mr-3"><a href="/support" className="a-reg">Support</a></p>
      </Col>
    </Row>
  )
}

export default Footer;
 