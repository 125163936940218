import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import HomePage from "./components/homePage/HomePage";
import BusinessLandingPage from "./components/homePage/Businesses/BusinessLandingPage";
import BusinessesPage from "./components/homePage/Businesses/BusinessesPage";
import AppPage from "./components/homePage/AppPage";
import BusinessSignUp from "./components/businessAuth/BusinessSignUp";
import BusinessSignIn from "./components/businessAuth/BusinessSignIn";
import BusinessAccountPage from "./components/businessUser/BusinessAccountPage";
import NoniOSLandingPage from "./components/landingPage/NoniOSLandingPage";
import PrivacyPage from "./components/termsAndSupport/PrivacyPage";
import TermsPage from "./components/termsAndSupport/TermsPage";
import SupportPage from "./components/termsAndSupport/SupportPage";

import "./App.css";

const supportsHistory = "pushState" in window.history;

class App extends Component {
  render() {
    return (
      <Router forceRefresh={!supportsHistory}>
        <Switch>
          <Route exact path="/" component={BusinessLandingPage} />
          <Route exact path="/incomingLinks/*" component={BusinessLandingPage} />
          <Route path="/app" component={AppPage} />
          <Route exact path="/business" component={BusinessLandingPage} />
          <Route path="/business/signin" component={BusinessSignIn} />
          <Route path="/business/signup" component={BusinessSignUp} />
          <Route path="/business/account/*" component={BusinessAccountPage} />
          <Route path="/business/onboarding/*" component={BusinessAccountPage} />
          <Route path="/legal/privacy" component={PrivacyPage}/>
          <Route path="/legal/terms" component={TermsPage}/>
          <Route path="/support" component={SupportPage} />
          <Route exact path="/sponsorships" component={NoniOSLandingPage} />
          <Route path="*" component={BusinessLandingPage} />
        </Switch>
      </Router>
    );
  }
}

export default App;
