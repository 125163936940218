import { Business } from "../../../constants/ClassObjects";

export function currentOnboardStatus(businessUser:Business) {
  
  const statuses = businessUser.accountStatuses;
  const website  = (businessUser.website) ? businessUser.website : '';
  const moreInfoSet = (website.length > 0 && businessUser.address.address1.length > 0);
  const rewardsSet = (statuses.rewardsSet === true);
  const bothSubscriptionsActive = (statuses.adSubscription === "active");

  return { moreInfoSet, rewardsSet, bothSubscriptionsActive }
  
}