// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { Row, Col } from "react-bootstrap";

// Components
import BusinessContentRows from "./BusinessContentRows";
import ShareRequestModal from "./ShareRequestModal";
import RewardContentModal from "./RewardContentModal";

// Other Class Objects/Components
import { PhotoMetric } from "../../../constants/PointsClassObjects";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";

const BusinessContentPage: React.FC<any> = (props:any) => {

  const { currentBusiness } = props;

  const [photosArray, setPhotosArray] = useState<PhotoMetric[]>([]);
  const [photosLoading, setPhotosLoading] = useState(false);
  const [photoToNudge, setPhotoToNudge] = useState<PhotoMetric|undefined>(undefined);
  const [photoToReward, setPhotoToReward] = useState<PhotoMetric|undefined>(undefined);

  const [errorMessage, setErrorMessage] = useState("");
  
  useEffect(() => {
    if (currentBusiness && photosArray.length <= 0) {
      queryPhotos(currentBusiness.id);
    }
  }, [photosArray]);

  // query all photos posted at this location
  function queryPhotos(businessId: string) {

    if (!photosLoading) {

      setPhotosLoading(true);

      db.collection("photoMetrics")
        .where("businessId", "==", businessId)
        .orderBy("created_at", "desc")
        .onSnapshot((photoMetricsSnapshot) => {
          const photosArray = photoMetricsSnapshot.docs.map((doc) => new PhotoMetric(doc.id, doc.data()));
          setPhotosArray(photosArray);
          setPhotosLoading(false);
        });
    }
  }
  
  return (
    <div>
      <Row>
        <Col>
          { (photoToReward !== undefined) &&
            <div id="rewardOverlay">
              <RewardContentModal
                contentArray={ photosArray }
                photoToReward={ photoToReward }
                setPhotoToReward={ setPhotoToReward }
              />
            </ div>
          }
          { (photoToNudge !== undefined) &&
            <div id="requestOverlay">
              <ShareRequestModal
                contentArray={ photosArray }
                photoToNudge={ photoToNudge }
                setPhotoToNudge={ setPhotoToNudge }
              />
            </ div>
          }
          <Row className="account-page-title-row">
            <Col className="text-left">
              <h1>Content</h1>
              <hr/>
            </Col>
          </Row>
          <div hidden={ photosLoading || photosArray.length <= 0 }>
            <div className="photoData">
              <BusinessContentRows
                contentArray={ photosArray }
                setPhotoToNudge={ setPhotoToNudge }
                setPhotoToReward={ setPhotoToReward }
                setErrorMessage={ setErrorMessage }
              />
            </div>
          </div>
          <div hidden={ photosLoading || photosArray.length > 0 }>
            <h3>No Photos Yet!</h3>
            <p>Tell your customers to get buzzin' and post on GiveBee to begin earning points!</p>
          </div>
        </Col>
      </Row>
      <Row hidden={ errorMessage === "" }>
        <Col>
          <h3>Error occurred:</h3>
          <p>{`${errorMessage}`}</p>
        </Col>
      </Row>
    </div>        
  );
};

export default BusinessContentPage;