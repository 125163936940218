import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../../assets/images/GiveBee_201.png";

import "../homePage/HomePage.css";

interface PrivacyPageProps extends RouteComponentProps {}

const PrivacyPage: React.FC<PrivacyPageProps> = ({ history }) => {

    return (
      <Container className="businessSignUp">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logoNorm"
          onClick={() => {
            history.push("/")
          }}
        />
        <Row>
          <Col>
            <h1 className="mt-3">
              Privacy Policy
            </h1>
            <p>
              Last Updated: October 15, 2020
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <p>
              At GiveBee, our mission is to allow any individual or business to own their digital presence and be economically empowered by it. We are committed to protecting the privacy of all stakeholders and members of our community.
            </p>
            <p>
              This privacy policy describes how GiveBee, LLC ("GiveBee", “we” or “us”) will handle any personal information that it collects about you, as you use our websites (“Website” or “Site”), our mobile applications (“Apps”) or other services (together with the Site and Apps, “Services”). It describes what data we will collect, how we will use it, and whom we may share it with. By visiting and using our Services, you are accepting the terms in this policy and consenting to the practices outlined within.
            </p>
            <p>
              Remember that your use of GiveBee’s Services is at all times subject to our Terms of Use, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.
            </p>
            <h3>What we collect</h3>
            <p>
              We collect and use the following information to provide, improve and protect our Services:
            </p>
            <p>
              Information you provide. We collect the content and other information you provide when you use our Services, including information like your name, profile picture, email address, payment information, linked financial account numbers, or phone number when you submit them to us, which we may also associate with an account that you create.            </p>
            <p>
              Content you create. We collect the content you post through our Services, including media formats like photos or videos.
            </p>
            <p>
              Financial information from third parties. When you connect your bank account or credit card using one of third-party partners like Plaid, we automatically collect transactions associated with this account.
            </p>
            <p>
              Things you do. We also collect information about how you use our Services or otherwise interact with us. For example, when you redeem a Reward, we know what restaurant it was for. If you unlocked a reward from a screenshot, we attribute it to the person who originally took the photo and posted it.
            </p>
            <p>
              Device information. We collect information from or about the computers, phones, or other devices you use to access the Services, depending on the permissions you’ve granted. Here are some examples of the device information we may collect:
            </p>
            <ul>
              <li>
                Attributes such as the operating system, hardware version, device settings, file and software names and types, battery and signal strength, and device identifiers;
              </li>
              <li>
                Device location, including specific geographic locations, such as through GPS, Bluetooth, or WiFi signals; and
              </li>
              <li>
                Connection information such as the name of your mobile operator or ISP, browser type, language and time zone, mobile phone number and IP address.
              </li>
            </ul>
            <p>
              Information from third-party partners. We receive information about you and your activities on and off the Site from third-party partners, such as analytics from Google.            </p>
            <p>
              Cookies and other technologies. We use technologies like cookies in our Services. For example, cookies help us with things like understanding how you are interacting with our Services, and improving them based on that information. You can set your browser to not accept cookies, but this may limit your ability to use the Services.            </p>
            <p>
              Feedback and Support. You may also choose to provide feedback on our Services or contact us for support, and we will keep a record of that communication.
            </p>

            <h3>How we use information we collect</h3>
            <p>
              Provision of the Services. We use the information we collect from our Services to provide, maintain, protect and improve the Services, to develop new ones, and to protect GiveBee and our users. We may also use this information to better provide our Services to you.
            </p>
            <p>
              Contact. We use your information to send you marketing communications, communicate with you about our Services and let you know about our policies and terms. We also use your information to respond to you when you contact us.
            </p>
            <p>
              Cookies and other technologies. We use information collected from cookies and other technologies, like pixel tags, to improve your user experience and the overall quality of our Services.
            </p>
            <p>
              Retention. We'll retain information for as long as we need it to for the uses described above. We may also keep aggregated data even if we deprecate the Services.
            </p>
            <p>
              Security. We use industry standard security best practices to protect your account. Our current account authentication is provided by Google and any connections to your financial accounts are provided by Plaid.
            </p>
            <h3>With whom we share information</h3>
            <p>
              We may share information as discussed below but we will not sell it to third parties.
            </p>
            <p>
              Businesses. If you use our Services to create content for a business, in order to properly use our Services or earn Rewards, and in accordance with your direction, we share your relevant personal information, matched transactions, and content with the business you post for. Matched transactions are the transactions from your linked bank and credit card accounts that are matched with a business you are sponsored by. You explicitly acknowledge that you are intentionally providing your personal information, matched transactions, and content to those businesses through our services, and GiveBee is not responsible for those businesses' use of your personal information or content.            </p>
            <p>
              Other users. Some of our Services display information like your name and profile picture to other Users in places like the photos you share. For example, if you post a photo through GiveBee, Users  may discover your post on our community feeds and see your name and profile picture when they view the photo.            </p>
            <p>
              Customers. If you are a business using our Services, we may share contact or other information that you provide through our Services to potential or actual Customers.
            </p>
            <p>
              With Your Consent. We may get your consent to share personal information about you with additional third parties.
            </p>
            <p>
              Law and Order. We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to (a) comply with the law; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of GiveBee or our users; or (d) protect GiveBee's property rights.
            </p>
            <p>
              Aggregated and Non-Personal Information. We may also share information with third parties in a manner that does not identify particular individuals, for example, information that has been aggregated with other records.
            </p>
            <h3>Personal Data of Children</h3>
            <p>
              As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 13; If you are under the age of 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from anyone under the age of 13, we will delete that information as quickly as possible. If you believe that anyone under the age of 13 may have provided us Personal Data, please contact us at support@givebee.co.
            </p>
            <h3>California Resident Rights</h3>
            <p>
              If you are a California resident, you have the rights outlined in this section. Please see the “Exercising Your Rights” section below for instructions regarding how to exercise these rights. If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident, the portion that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether any of the following applies to you, please contact us at info@givebee.co.
            </p>
            <p>Access</p>
            <p>
              You have the right to request certain information about our collection and use of your Personal Data over the past 12 months. We will provide you with the following information:
            </p>
            <ul>
              <li>
                The categories of Personal Data that we have collected about you.
              </li>
              <li>
                The categories of sources from which that Personal Data was collected.
              </li>
              <li>
                The business or commercial purpose for collecting or selling your Personal Data.
              </li>
              <li>
                The categories of third parties with whom we have shared your Personal Data.
              </li>
              <li>
                The specific pieces of Personal Data that we have collected about you.
              </li>
            </ul>
            <p>
              If we have disclosed your Personal Data for a business purpose over the past 12 months, we will identify the categories of Personal Data shared with each category of third party recipient.
            </p>
            <p>
              If we have sold your Personal Data over the past 12 months, we will identify the categories of Personal Data purchased by each category of third party recipient.
            </p>
            <p>Deletion</p>
            <p>
              You have the right to request that we delete the Personal Data that we have collected from you. Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or complete a transaction or other action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.
            </p>
            <p>Exercising Your Rights</p>
            <p>
              To exercise the rights described above, you must send us a request that (1) provides sufficient information to allow us to verify that you are the person about whom we have collected Personal Data, and (2) describes your request in sufficient detail to allow us to understand, evaluate, and respond to it. Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify you and complete your request. You do not need an account to submit a Valid Request.
            </p>
            <p>
              We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive, or manifestly unfounded. If we determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
            </p>
            <p>
              You may submit a Valid Request by emailing us at: support@givebee.co
            </p>
            <p>Personal Data Sales Opt-Out</p>
            <p>
              You have the right to opt-out of the sale of your Personal Data.
            </p>
            <p>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</p>
            <p>
              We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA. However, we may offer different tiers of our Services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates, or levels of quality of the goods or services you receive related to the value of Personal Data that we receive from you.
            </p>
            <h3>Other State Law Privacy Rights</h3>
            <p>California Resident Rights</p>
            <p>
              Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at info@givebee.co.
            </p>
            <p>Nevada Resident Rights</p>
            <p>
              If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at support@givebee.co with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account.
            </p>
            <h3>Changes to this Privacy Policy</h3>
            <p>
              We may revise this Privacy Policy from time to time, and will post the most current version on the Site. If we are involved in a reorganization, merger, acquisition or sale of our assets, your information may be transferred as part of that deal.
            </p>
            <h3>Contact</h3>
            <p>
              If you have questions or concerns about GiveBee, our Services and privacy, contact us at info@givebee.co.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    );
}

export default PrivacyPage;
