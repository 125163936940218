export function getCurrentTimestamp() {
  return (Math.round((new Date()).getTime() / 1000));
}

export function timestampToDate(timestamp:any) {
  var a = new Date(timestamp * 1000);
  var month = a.getMonth();
  var date = a.getDate();
  var year = a.getFullYear();
  var time =  (month + 1) + '/' + date + '/' + year;
  return time;
}

export function firstDayOfCurrentMonthTimestamp() {

  const now = new Date();
  const y = now.getFullYear();
  const m = now.getMonth();

  const firstDay = new Date(y, m, 1);
  // @ts-ignore
  return timestampToDate(Math.round(firstDay / 1000) | 0);
}

export function lastDayOfCurrentMonthTimestamp() {

  const now = new Date();
  const y = now.getFullYear();
  const m = now.getMonth();

  const lastDay = new Date(y, m + 1, 0);
  // @ts-ignore
  return timestampToDate(Math.round(lastDay / 1000) | 0);
}

export function yearFirstToMonthFirst(inputDate:string) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }
}

export function convertToTimeAgo(timeStampInt:any) {

  if (timeStampInt <= 0) { return ""; }

  const secInHour = 60.0 * 60.0
  const secInDay = secInHour * 24.0
  const spanSinceCreate = (getCurrentTimestamp() - timeStampInt);

  let convertString = ""

  if (spanSinceCreate < secInHour) {
      const number = Math.floor(spanSinceCreate / 60.0);
      convertString = `${number} min ago`
  } else if (spanSinceCreate < secInDay) {
      const number = Math.floor(spanSinceCreate / secInHour)
      convertString = (number < 2) ? `${number} hr ago` : `${number} hrs ago`
  } else {
      convertString = timestampToDate(timeStampInt);
  }
  return convertString;
}