import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import BusinessAccountEdit from "../BusinessAccountEdit";

const BusinessAccountSettingsPage: React.FC<any> = ({ currentBusiness }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div>
        <Row className="account-page-title-row">
          <Col className="text-left">
            <h1>Account Settings</h1>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col>
            <BusinessAccountEdit currentBusiness={currentBusiness} />
          </Col>           
        </Row>
    </div>
  );
};

export default BusinessAccountSettingsPage;