import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { db } from "../../../firebase";

import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";
import { convertUSDDecimalToGiveBeeInt, convertGiveBeeIntToUSDDecimal } from "../../../helpers/PointsAndMoneyConversions";

const EditRewards: React.FC<any> = ({ currentBusiness }) => {

  const [inputFields, setInputFields] = useState<Array<any>>([
    { reward: '', amount: '' }
  ]);

  const [rewardsLoading, setRewardsLoading] = useState(false);

  const [expandedHowToEarn, setHowToEarnState] = useState(false);
  const [expandedHowManyPoints, setHowManyPointsState] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  
  useEffect(() => {
    if (currentBusiness) {
      queryBusinessRewards(currentBusiness.id)
    }
  }, []);

  async function queryBusinessRewards(businessId:string) {

    const rewardsData = (await db.collection('businessRewards').doc(businessId).get()).data();

    if (rewardsData) {
      const arr = rewardsData.rewards;
      arr.map((r:any) => {
        r.amount = (r.amount / 10000).toFixed(2);
      });
      setInputFields([...arr]);
    }
  }

  const RewardsInputFields = inputFields.map((inputField, index) => (
    <React.Fragment key={`${inputField}~${index}`}>
      <div className="form-group col-sm-5">
        <label htmlFor="reward">Reward</label>
        <input
          type="text"
          className="form-control form-field field-rewards"
          id="reward"
          name="reward"
          placeholder="Free drink"
          required={true}
          value={inputField.reward}
          onChange={(event) => handleInputChange(index, event)}
        />
      </div>
      <div className="form-group col-sm-2">
        <label htmlFor="amount">Cost ($ USD)</label>
        <input
          type="text"
          className="form-control field-rewards"
          id="amount"
          name="amount"
          placeholder="2.50"
          required={true}
          value={ inputField.amount }
          onChange={(event) => handleInputChange(index, event)}
        />
      </div>
      <div className="form-group col-sm-2">
        <label htmlFor="points">Points</label>
        <input
          type="text"
          className="form-control field-rewards"
          id="points"
          name="points"
          placeholder={ (inputField.amount !== '') ? `${(inputField.amount * 100)}` : "250" }
          disabled={true}
          value={ inputField.amount * 100 }
        />
      </div>
      <div className="form-group col-sm-2">
        <button
          className="btn btn-link button-add-sub"
          type="button"
          onClick={() => handleRemoveFields(index)}
        >
          -
        </button>
        <button
          className="btn btn-link button-add-sub"
          type="button"
          onClick={() => handleAddFields()}
        >
          +
        </button>
      </div>
    </React.Fragment>
  ));


  const handleInputChange = (index:any, event:any) => {
    const values = [...inputFields];
    if (event.target.name === "reward") {
      values[index].reward = event.target.value;
    } else {
      values[index].amount = event.target.value;
    }
    setInputFields(values);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ reward: '', amount: '' });
    setInputFields(values);
  };

  const handleRemoveFields = (index:any) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleSubmit = async(event:any) => {

    event.preventDefault();
    setRewardsLoading(true);
    try {
      const arr = [...inputFields];
      arr.map((r:any, index:any) => {
        r.amount = convertUSDDecimalToGiveBeeInt(r.amount);
      });
      const rewardsRef = db.collection('businessRewards').doc(currentBusiness.id);
      const businessRef = db.collection('businesses').doc(currentBusiness.id);
      await rewardsRef.set({ rewards: arr }, { merge: true });
      await businessRef.set({ accountStatuses: { rewardsSet: true } }, { merge: true });

      arr.map((r:any) => {
        r.amount = convertGiveBeeIntToUSDDecimal(r.amount);
      });
      setInputFields([...arr]);
      setRewardsLoading(false);
    } catch (err) {
      setRewardsLoading(false);
      setErrorMessage(`Error in setting rewards: ${err.message}`);
    }
  }

  return (
    <div>
      <Row>
        <Col>
        <Row>
          <Col>
            <h5 className="text-left font-weight-demibold">How do customers earn points?</h5>
          </Col>
          <Col className="text-left">
            <button
              className="button-link button-readMore"
              onClick= {(e:any) => setHowToEarnState(!expandedHowToEarn)}
            >
              { expandedHowToEarn ? "Hide" : "Read More" }
            </button>
          </Col>
        </Row>
          {
            expandedHowToEarn && 
            <Row>
             <Col>
              <p className="text-left">
                Once you "sponsor" a customer with a GiveBee card, they can earn points in the following ways:
              </p>
              <ul>
                <li className="text-left">
                  <strong className="font-weight-demibold">Purchases at your business:</strong> 1.65 pts per $1 spent
                </li>
                <li className="text-left mt-2">
                  <strong className="font-weight-demibold">Generating views for your business:</strong> 2 pts per view
                </li>
                <li className="text-left mt-2">
                  <strong className="font-weight-demibold">Generating website visits with their photos:</strong> 8 pts per visit
                </li>
                <li className="text-left mt-2">
                  <strong className="font-weight-demibold">Posting photos that you reward:</strong> as many points as you want to give!
                </li>
              </ul>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row>
        <Col>
        <Row>
          <Col className="text-left vertical-align-bottom">
            <h5 className="font-weight-demibold mt-3">How many points should each reward be worth?</h5>
          </Col>
          <Col className="text-left pt-3 pb-0">
            <button
              className="button-link button-readMore"
              onClick= {(e:any) => setHowManyPointsState(!expandedHowManyPoints)}
            >
              { expandedHowManyPoints ? "Hide" : "Read More" }
          </button>
          </Col>
        </Row>
          {
            expandedHowManyPoints &&
            <Row>
              <Col>
                <p className="text-left">
                  We recommend setting rewards at their <strong className="font-weight-demibold">dollar cost or lower</strong>.
                  Enter the cost of your reward and we will convert it to the correct points amount.
                </p>
                <p className="text-left">A reward with <strong className="font-weight-demibold">cost of $2.50</strong> will cost a customer <strong className="font-weight-demibold">250 points</strong> to earn.</p>
                <p className="text-left">
                  Remember, you can always choose how many sponsored customers will be able to access these rewards
                  so make your sponsored customers extra feel special!
                </p>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h4 className="text-left">Set Rewards</h4>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              { RewardsInputFields }
            </div>
            <div className="submit-button">
              <button
                className="btn btn-primary mr-2 button-save"
                type="submit"
                disabled={rewardsLoading}
              >
                Save
              </button>
              <Spinner hidden={!rewardsLoading} animation="border" />
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default EditRewards;