// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { Row, Col } from "react-bootstrap";

// Components and Other Class Objects
import { PhotoMetric, PointsTransaction, TransactionMatch } from "../../../constants/PointsClassObjects";
import { LazyImage } from "../../../helpers/LazyImage";
import { convertToTimeAgo, yearFirstToMonthFirst } from "../../../helpers/TimeAndDateFunctions";
import { convertGiveBeeIntToUSDString, convertGiveBeeIntToClientPoints } from "../../../helpers/PointsAndMoneyConversions";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";
import "./BusinessActivityHistoryPage.css";

import contentIcon from "../../../assets/images/content-engagements-icon.svg";
import flyMoneyIcon from "../../../assets/images/transactionMatch-icon.svg";
import coinIcon from "../../../assets/images/coin-icon.svg";

const BusinessActivityHistory: React.FC<any> = (props:any) => {

  const { currentBusiness, setErrorMessage } = props;

  // Loading variables
  const [transactionMatchesLoading, setTransactionMatchesLoading] = useState(false);
  const [photoMetricsLoading, setPhotoMetricsLoading] = useState(false);
  const [pointsTransactionsLoading, setPointsTransactionsLoading] = useState(false);

  // Data for History
  const [transactionMatchesArray, setTransactionMatchesArray] = useState<TransactionMatch[]>([]);
  const [photoMetricsArray, setPhotoMetricsArray] = useState<PhotoMetric[]>([]);
  const [pointsTransactionsArray, setPointsTransactionsArray] = useState<PointsTransaction[]>([]);
  
  useEffect(() => {
    if (currentBusiness) {
      queryPhotoMetrics(currentBusiness.id);
      queryTransactionMatches(currentBusiness.id);
      queryPointsTransactions(currentBusiness.id);
    }
  }, []);

  /**
   * 
   * @param businessId business user id to query respective data
   * 
   * Queries the paid photo metric documents related to this business
   */
  const queryPhotoMetrics = async(businessId:string) => {
    try {
      if (!photoMetricsLoading) {
        setPhotoMetricsLoading(true);
        const photoMetricsSnapshot = await db
          .collection("photoMetrics")
          .where("businessId", "==", businessId)
          .orderBy("latest_points_increment", "desc")
          .get();
        
        const dataArray = photoMetricsSnapshot.docs.map((doc) => new PhotoMetric(doc.id, doc.data()));
        setPhotoMetricsArray(dataArray);
        setPhotoMetricsLoading(false);
      }
    } catch (err) {
      setErrorMessage(err.message);
      setPhotoMetricsLoading(false);
    }
  }

  /**
   * Actual content for the column containing the paid photo metrics related to the business user
   */
  const PhotoMetricsList = photoMetricsArray.map((photoMetric:PhotoMetric, index:number) => {
    return (
      <div
        key={`photoMetric-${index}`}
        className="mt-3"
      >
        <Row className="activityRow">
          <Col xs={12}>
            <LazyImage
              className="activityImage"
              alt="photo was deleted :("
              src={photoMetric.url}
            />
            <Row className="mt-3">
              <Col>
                <p className="font-weight-demibold">{photoMetric.userInfo.firstName ? photoMetric.userInfo.firstName : ""} {photoMetric.userInfo.lastName ? photoMetric.userInfo.lastName : ""} {convertToTimeAgo(photoMetric.created_at)}</p>
                {/* <Row className="metricsRow">
                  <Col>
                    <p>{convertGiveBeeIntToUSDString(photoMetric.totalPostViewCharge)}</p>
                    <img alt="" className="regularIcon regularIcon-eye" src={photoViewsIcon}></img>
                  </Col>
                  <Col>
                    <p>{convertGiveBeeIntToUSDString(photoMetric.totalWebsiteVisitCharge)}</p>
                    <img alt="" className="regularIcon" src={websiteVisitsIcon}></img>
                  </Col>
                  <Col>
                    <p>{convertGiveBeeIntToUSDString(photoMetric.photoRewardCharge)}</p>
                    <img alt="" className="regularIcon" src={businessBoostIcon}></img>
                  </Col>
                </Row> */}
                <p><strong className="font-weight-demibold">Total Charge:</strong> {convertGiveBeeIntToUSDString(photoMetric.totalCharge)}</p>
                <p><strong className="font-weight-demibold">Total Points:</strong> +{convertGiveBeeIntToClientPoints(photoMetric.totalPointsGenerated)} <img alt="" className="regularIcon mb-1" src={coinIcon} /></p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    );
  });

  /**
   * 
   * @param businessId business user id
   * 
   * Query the transaction matches belonging to this business
   */
  const queryTransactionMatches = async(businessId:string) => {
    try {
      if (!transactionMatchesLoading) {
        setTransactionMatchesLoading(true);
        const transactionMatchesSnapshot = await db
          .collection("transactionMatches")
          .where("businessId", "==", businessId)
          .orderBy("date", "desc")
          .get();
        
        const dataArray = transactionMatchesSnapshot.docs.map((doc) => new TransactionMatch(doc.id, doc.data()));
        setTransactionMatchesArray(dataArray);
        setTransactionMatchesLoading(false);
      }
    } catch (err) {
      setErrorMessage(err.message);
      setTransactionMatchesLoading(false);
    }
  }

  /**
   * Actual content for the column containing the transaction matches
   */
  const TransactionMatchesList = transactionMatchesArray.map((transMatch:TransactionMatch, index:number) => {
    return (
      <div
        key={`transactionMatch-${index}`}
        className="mt-3"
      >
        <Row className="activityRow">
          <Col xs={12}>
            <Row className="mt-3">
              <Col>
                <p className="font-weight-demibold">{transMatch.firstName} {transMatch.lastName} {yearFirstToMonthFirst(transMatch.date)}</p>
                <p className="font-weight-demibold">{transMatch.name}</p>
                <p><strong className="font-weight-demibold">Total Spent:</strong> ${transMatch.amount}</p>
                <p><strong className="font-weight-demibold">Points Earned:</strong> +{convertGiveBeeIntToClientPoints(transMatch.pointsGenerated)} <img alt="" className="regularIcon mb-1" src={coinIcon} /></p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    );
  });

  /**
   * 
   * @param businessId user id for the current business user
   * 
   * Function queries any redeemed points actions related to this business
   */
  const queryPointsTransactions = async(businessId:string) => {
    try {
      if (!pointsTransactionsLoading) {
        setPointsTransactionsLoading(true);
        const pointsTransactionsSnapshot = await db
          .collection("pointsTransactions")
          .where("businessId", "==", businessId)
          .orderBy("created_at", "desc")
          .get();
        
        const dataArray = pointsTransactionsSnapshot.docs.map((doc) => new PointsTransaction(doc.id, doc.data()));
        setPointsTransactionsArray(dataArray);
        setPointsTransactionsLoading(false);
      }
    } catch (err) {
      setErrorMessage(err.message);
      setPointsTransactionsLoading(false);
    }
  }

  /**
   * Actual content for the column containing the points
   */
  const PointsTransactionsList = pointsTransactionsArray.map((pointsTrans:PointsTransaction, index:number) => {
    return (
      <div
        key={`transactionMatch-${index}`}
        className="mt-3"
      >
        <Row className="activityRow">
          <Col xs={12}>
            <Row className="mt-3">
              <Col>
                <p className="font-weight-demibold">{pointsTrans.firstName} {pointsTrans.lastName} {convertToTimeAgo(pointsTrans.created_at)}</p>
                <p className="font-weight-demibold">{pointsTrans.rewardChosen}: {convertGiveBeeIntToClientPoints(pointsTrans.pointsRedeemed)} <img alt="" className="mb-1 regularIcon" src={coinIcon}/></p>
                <p><strong className="font-weight-demibold">Prior Balance:</strong> {convertGiveBeeIntToClientPoints(pointsTrans.pointsBalanceBefore)} <img alt="" className="mb-1 regularIcon" src={coinIcon}/></p>
                <p><strong className="font-weight-demibold">New Balance:</strong> {convertGiveBeeIntToClientPoints(pointsTrans.pointsBalanceAfter)} <img alt="" className="mb-1 regularIcon" src={coinIcon}/></p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    );
  });

  return (
    <Row className="mt-3">
      <Col xs={12} md={4}>
        <h3 className="activityColTitle"><img alt="" className="activityTitleIcon" src={contentIcon} /> Content Engagements</h3>
        <hr/>
        { (photoMetricsArray.length > 0) && PhotoMetricsList }
        { (photoMetricsArray.length <= 0) && <p>No paid content metrics yet! Tell customers to upload and post photos on GiveBee!</p> }
      </Col>
      <Col xs={12} md={4}>
        <h3 className="activityColTitle"><img alt="" className="activityTitleIcon" src={flyMoneyIcon} /> Sponsored Transactions</h3>
        <hr/>
        { (transactionMatchesArray.length > 0) && TransactionMatchesList }
        { (transactionMatchesArray.length <= 0) && <p>No transactions have occurred yet! Tell sponsored members to connect their bank account to begin earning 1.65pts per dollar!</p> }
      </Col>
      <Col xs={12} md={4}>
        <h3 className="activityColTitle"><img alt="" className="mb-1 activityTitleIcon" src={coinIcon} /> Redeemed Rewards</h3>
        <hr/>
        { (pointsTransactionsArray.length > 0) && PointsTransactionsList }
        { (pointsTransactionsArray.length <= 0) && <p>No rewards have been redeemed yet! Boost more photos to help your customers reach them!</p> }
      </Col>
    </Row>
  );
};

export default BusinessActivityHistory;