// React Components and Other Libraries
import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { Row, Col, Spinner } from "react-bootstrap";

// Components
import BusinessContentRows from "../BusinessContent/BusinessContentRows";
import ShareRequestModal from "../BusinessContent/ShareRequestModal";
import RewardContentModal from "../BusinessContent/RewardContentModal";
import BusinessAccountPerformance from "./BusinessAccountPerformance";

// Functions
import { firstDayOfCurrentMonthTimestamp, lastDayOfCurrentMonthTimestamp } from "../../../helpers/TimeAndDateFunctions"

// Components and Other Class Objects
import { PhotoMetric } from "../../../constants/PointsClassObjects";

// UI and CSS
import "../../businessAuth/BusinessSignUp.css";
import "../BusinessAccountPage.css";
import "../BusinessActivityHistory/BusinessActivityHistoryPage.css";

const BusinessDashboardPage:React.FC<any> = (props:any) => {

  const { currentBusiness } = props;

  // For BusinessNewContent
  const [photosLoading, setPhotosLoading] = useState(false);
  const [newPhotosArray, setNewPhotosArray] = useState<PhotoMetric[]>([]);
  // For ShareRequestModal
  const [photoToNudge, setPhotoToNudge] = useState<PhotoMetric|undefined>(undefined);
  // For RewardContentModal
  const [photoToReward, setPhotoToReward] = useState<PhotoMetric|undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (currentBusiness && newPhotosArray.length <= 0) {
      queryNewPhotos(currentBusiness.id);
    }
  }, [newPhotosArray]);

  // query all unseen photos posted at this location
  async function queryNewPhotos(businessId: string) {
    if (!photosLoading) {
      setPhotosLoading(true);
      try {
        const photoMetricsSnapshot = await db.collection("photoMetrics")
                                            .where("businessId", "==", businessId)
                                            .where("business_viewed_photo", "==", 0)
                                            .orderBy("created_at", "desc")
                                            .get();
        const photosDataArray = photoMetricsSnapshot.docs.map((doc) => new PhotoMetric(doc.id, doc.data()));
        setNewPhotosArray(photosDataArray);
        setPhotosLoading(false);
      } catch (err) {
        setErrorMessage(`Error in getting new content: ${err.message}`)
      }
    }
  }

  const BusinessNewContent = () => {
    return (
      <Row className="newContentBackground">
        <Col>
          <Spinner animation="border" hidden={ !photosLoading } />
          <div hidden={ photosLoading || newPhotosArray.length <= 0 }>
            <div className="photoData">
              <BusinessContentRows
                contentArray={ newPhotosArray }
                setPhotoToNudge={ setPhotoToNudge }
                setPhotoToReward={ setPhotoToReward }
                setErrorMessage={ setErrorMessage }
              />
            </div>
          </div>
          <div hidden={ photosLoading || newPhotosArray.length > 0 }>
            <h3>No New Photos!</h3>
            <p>Tell your customers to post photos on GiveBee to earn more points!</p>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="row-center">
      <Col>
        { (photoToReward !== undefined) &&
        <div id="rewardOverlay">
          <RewardContentModal
            contentArray={ newPhotosArray }
            photoToReward={ photoToReward }
            setPhotoToReward={ setPhotoToReward }
          />
        </ div>
        }
        { (photoToNudge !== undefined) &&
        <div id="requestOverlay">
          <ShareRequestModal
            contentArray={ newPhotosArray }
            photoToNudge={ photoToNudge }
            setPhotoToNudge={ setPhotoToNudge }
          />
        </ div>
        }
        <Row className="account-page-title-row">
          <Col className="text-left">
            <h1>Dashboard</h1>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <h3 className="font-weight-demibold">New Content</h3>
          </Col>
        </Row>
        <BusinessNewContent />
        <br />
        <Row className="mb-3">
          <Col>
            <h3 className="text-left font-weight-demibold">Account Performance</h3>
            <h4 className="text-left dateTitle">{firstDayOfCurrentMonthTimestamp()} - {lastDayOfCurrentMonthTimestamp()}</h4>
          </Col>
        </Row>
        <BusinessAccountPerformance currentBusiness={ currentBusiness } setErrorMessage={ setErrorMessage } />
        <Row hidden={ errorMessage === "" }>
          <Col>
            <h3>Error occurred:</h3>
            <p>{`${errorMessage}`}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BusinessDashboardPage;