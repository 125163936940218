// Component source: https://dev.to/slashgear_/creating-an-image-lazy-loading-component-with-react-4o13
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import placeHolder from "../assets/images/loading.gif";
import failedImg from "../assets/images/GiveBee_201.png";

const Image = styled.img`
  // Add a smooth animation on loading
  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  // I use utilitary classes instead of props to avoid style regenerating
  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }
  &.has-error {
    // fallback to placeholder image on error
    content: url(${failedImg});
  }
`
// @ts-ignore
export const LazyImage = ({ className, src, alt }) => {

  const [imageSrc, setImageSrc] = useState(placeHolder)
  const [imageRef, setImageRef] = useState()

  // @ts-ignore
  const onLoad = event => {
    event.target.classList.add("loaded")
  }

  // @ts-ignore
  const onError = event => {
    event.target.classList.add("has-error")
  }
  
  useEffect(() => {
    let observer:any;
    let didCancel = false;

    if (imageRef && imageSrc === placeHolder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              // when image is visible in the viewport + rootMargin
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setImageSrc(src);
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        )
        observer.observe(imageRef);
      } else {
        // Old browsers fallback, not all browsers allow observe
        setImageSrc(src)
      }
    }
    return () => {
      didCancel = true;
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    }
  })
  return <Image className={className} ref={setImageRef} src={imageSrc} alt={alt} />
}