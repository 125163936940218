import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Business } from "../../constants/ClassObjects";

import "./NavMenu.css"

const BusinessAccountNavModule: React.FC<any> = (props:any) => {

  const { currentBusiness } = props;

  const [business, setCurrentBusiness] = useState<Business|undefined>(undefined);
  
  useEffect(() => {
    if (currentBusiness) {
      const newBiz = new Business(currentBusiness.id, currentBusiness);
      setCurrentBusiness(newBiz);
    }
  }, [props.currentBusiness, currentBusiness]);

  return (
    <Row className="layout-nav-side-content-wrapper">
      <Col className="main-module-col">
        { business && <img id="moduleBusinessBadge" alt="" src={business.badgeImageUrl}/> }
        { business && <h2 id="moduleBusinessName" className="text-left">{business.businessName}</h2> }
      </Col>
    </Row>
  );
}

export default BusinessAccountNavModule;