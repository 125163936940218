import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { db } from "../../firebase";
import { RouteComponentProps } from 'react-router-dom';

import "../businessAuth/BusinessSignUp.css";

interface BusinessAccountEditProps extends RouteComponentProps {}

const BusinessAccountEdit: React.FC<BusinessAccountEditProps|any> = ({ currentBusiness }) => {

  const [businessId, setBusinessId] = useState("");
  const [adminFullName, setAdminFullName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [igHandle, setIGHandle] = useState("");

  const [submitLoading, setSubmissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  useEffect(() => {
    if (currentBusiness) {
      setBusinessId(currentBusiness.id);
      setAdminFullName(currentBusiness.adminFullName);
      setAddress1(currentBusiness.address.address1);
      setAddress2(currentBusiness.address.address2);
      setCity(currentBusiness.address.city);
      setState(currentBusiness.address.state);
      setZipCode(currentBusiness.address.zipCode);
      setWebsite(currentBusiness.website);
      setPhone(currentBusiness.phone);
    }
  }, []);

  const handleSubmitUserInfo = async (event:any) => {

    setSubmissionLoading(true);
    event.preventDefault();

    const newBusinessInfo = {
      address1: address1,
      address2: address2,
      address: {
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zipCode: zipCode,
      },
      adminFullName: adminFullName,
      city: city,
      fullName: adminFullName,
      phone: phone,
      state: state,
      website: website,
      igHandle: igHandle,
      zipCode: zipCode,
    };

    db.collection("businesses").doc(businessId).set(newBusinessInfo, { merge: true })
      .then(() => {
        setSubmissionLoading(false);
      })
      .catch((error) => {
        setSubmissionLoading(false);
        setErrorMessage(`Error updating business info: ${error.message}`);
      });
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={8}>
          <Form onSubmit={(e:any)=> handleSubmitUserInfo(e) }>
            <h4 className="text-left">Admin</h4>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                className="form-field"
                value={adminFullName}
                placeholder="Buzzy Buzz"
                required={true}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAdminFullName(e.target.value)}
              />
            </InputGroup>
            <h4 className="text-left">Address</h4>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                className="form-field"
                value={address1}
                placeholder="Address 1"
                required={true}
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAddress1(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                  type="text"
                  className="form-field"
                  value={address2}
                  placeholder="Address 2"
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAddress2(e.target.value)}
              />
            </InputGroup>
              <Row>
                <Col>
                <InputGroup className="mb-3">
                  <FormControl
                      type="text"
                      className="form-field"
                      value={city}
                      placeholder="City"
                      required={true}
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
                    />
                </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <FormControl
                      type="text"
                      className="form-field"
                      value={state}
                      placeholder="State"
                      required={true}
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <FormControl
                      type="text"
                      className="form-field"
                      value={zipCode}
                      placeholder="Zip"
                      required={true}
                      onChange={(e:React.ChangeEvent<HTMLInputElement>) => setZipCode(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
            <h4 className="text-left">Phone</h4>
            <InputGroup className="mb-3">
              <FormControl
                type="phone"
                className="form-field"
                value={phone}
                placeholder="(312) 123-4567"
                required
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
              />
            </InputGroup>
            <h4 className="text-left">Website</h4>
            <InputGroup className="mb-3">
              <FormControl
                type="website"
                className="form-field"
                value={website}
                placeholder="https://www.beezkneez.com"
                required
                onChange={(e:React.ChangeEvent<HTMLInputElement>) => setWebsite(e.target.value)}
              />
            </InputGroup>
            <h4 className="text-left">Social Accounts</h4>
            <InputGroup>
              <InputGroup.Prepend>
                <span className="input-group-text form-field" id="basic-addon1">@</span>
              </InputGroup.Prepend>
              <FormControl
                className="form-field"
                type="text"
                value={igHandle}
                placeholder="Instagram username"
                onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => { setIGHandle(e.target.value) }}
              />
            </InputGroup>
            <Spinner hidden={!submitLoading} animation="border" />
            <button id="paymentButton" disabled={ !businessId || submitLoading }>Set Business Info</button>
            {errorMessage && <p>{errorMessage}</p>}
          </Form>
        </Col>
        <Col xs={12} md={4}>
          <Row>
            <Col>
              <h3>Questions?</h3>
              <p>Text Us: (847) 856-9342</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BusinessAccountEdit;