import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../../assets/images/GiveBee_201.png";

import "../homePage/HomePage.css";

interface TermsPageProps extends RouteComponentProps {}

const TermsPage: React.FC<TermsPageProps> = ({ history }) => {
  return (
    <Container className="businessSignUp">
      <img
        src={logo}
        alt="GiveBee - Get the people buzzin'!"
        className="logoNorm"
        onClick={() => {
          history.push("/")
        }}
      />
      <Row>
        <Col>
          <h1 className="mt-3">
            Terms of Service
          </h1>
          <p>
            Last Updated: October 15, 2020
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <p>
            Thanks for using GiveBee! We're buzzin' with excitement to have you!
            These Terms and Conditions of Service (“Agreement” or “Terms”) are a legally binding agreement between the undersigned (“User”, the User may be referred to as “you” or “your”) and GiveBee LLC, a Delaware Limited Liability Corporation (“GiveBee,” “we,” “us,” or “our”). Through your use or downloading of the App or the Services referenced in the Agreement, you accept the Terms and Conditions. If you do not agree to the Terms, do not download or use the App.
            Get social media content straight from your customers and see the followers that come in because of their posts!
          </p>
          <p>
            Email: support@givebee.co
          </p>
          <p>
            These Terms and Conditions of Service (“Agreement” or “Terms”) are a legally binding agreement between the undersigned (“User”, the User may be referred to as “you” or “your”) and GiveBee LLC, a Limited Liability Corporation (“GiveBee,” “we,” “us,” or “our”). In this document, rewards (“Rewards”, “rewards”, “points”, “offers”, “giveaways”, or “FreeBees”) are mentioned as a core offering of our Services and a thorough definition and terms around these Rewards are defined below. Your use of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in this document as well as those in the Privacy Policy and any other relevant policies. Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms. Please note that we do not currently support use by individuals under the age of thirteen (13). If you are under the age of thirteen (13), please do not attempt to register for or otherwise use the Services. If we learn you are under the age of thirteen (13), we will terminate your account and delete any personal information provided by you as quickly as possible. If you believe that anyone under the age of 13 may have registered for the Services or provided us with any personal information, please contact us at support@givebee.co.
          </p>
          <p>
            Please read these Terms carefully. They cover important information about Services provided to you. These Terms include information about future changes to these Terms, limitations of liability, a class action waiver and resolution of disputes by arbitration instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
          </p>
          <p>
            ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
          </p>
          <p className="font-weight-demibold">GiveBee Account</p>
          <p>
            To use the Services, you need to establish an account by signing up through our App (the “Account”). You agree to provide accurate, current and complete information about yourself. Registration data and other information about you and this Agreement are governed by our Privacy Policy. You are responsible for keeping your password secret and secure. Please notify us of any unauthorized use of your password, account or any other breach of security of which you become aware and we will do our best to secure your account. 
          </p>
          <p>
            We offer Accounts for two different purposes: Personal Accounts and Business Accounts. Once you create a personal account, you will be able to perform the basic actions listed below.
          </p>
          <p>
            Personal GiveBee Accounts allow you to:
          </p>
          <ul>
            <li>
              Update and edit your profile.
            </li>
            <li>
              Explore photos posted other by members of our community.
            </li>
            <li>
              Upload your own photos and tag business locations, food, and other photo details.
            </li>
            <li>
              Earn points, rewards, and giveaways given by our partnered business customers.
            </li>
            <li>
              Share photos directly with our business customers.
            </li>
            <li>
              Receive points in exchange for your posted content if one of our business customers chooses to reward your content.
            </li>
            <li>
              Collaborate with our business partners and post to other social platforms in exchange for points or rewards exchanged on GiveBee.
            </li>
          </ul>
          <p>
            Approved Business Accounts allow you to:
          </p>
          <ul>
            <li>
              Receive and view content posted by Users that tag your approved business location.
            </li>
            <li>
              Sponsor users and activate loyalty cards that allow these users to earn points towards rewards.
            </li>
            <li>
              Send points to community users as a reward for the content they share on GiveBee.
            </li>
            <li>
              Collaborate with community users and reward them with points in exchange for sharing content to other platforms.
            </li>
            <li>
              Participate in contests and giveaways that can be conducted outside of the GiveBee platform and on other social platforms.
            </li>
            <li>
              Edit and update rewards at any time you choose.
            </li>
          </ul>
          <p>
            By creating a GiveBee Account, you are not entering an employee or contractor agreement.
          </p>
          <p className="font-weight-demibold">Permissions You Give to Us</p>
          <ul>
            <li>
              You grant us and any business you tag, a license to your content. When you share, post, or upload content, you hereby grant to us and the tagged business a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publick perform or display, translate, and create derivative works of your content. Content will continue to appear on our Services or other platforms. if you shared it with others and they have not deleted it. By tagging a Business on your photo, you agree to let the Business use your photo as advertisement or any commercial use.
            </li>
            <li>
              You agree that we can download and install updates to the Service on your device.
            </li>
          </ul>
          <p className="font-weight-demibold">Additional Rights We Retain</p>
          <ul>
            <li>
              If you select a username or similar identifier for your account, we may change it if we believe it is appropriate or necessary (for example, if it infringes someone's intellectual property or impersonates another user).
            </li>
            <li>
              If you use content covered by intellectual property rights that we have and make available in our Service (for example, images, designs, videos, or sounds we provide that you add to content you create or share), we retain all rights to our content (but not yours).
            </li>
            <li>
              You must obtain written permission from us to use our intellectual property and trademarks or similar marks.
            </li>
            <li>
              You must obtain written permission from us or under an open source license to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.
            </li>
          </ul>
          <p>
            Content Removal and Disabling or Termination Your Account
          </p>
          <ul>
            <li>
              We can remove any content or information you share on the Service if we believe that it violates these Terms of Use or we are permitted or required to do so by law. We can refuse to provide or stop providing all or part of the Service to you (including terminating or disabling your account) immediately to protect our community or services, or if you create risk or legal exposure for us, violate these Terms of Use or our policies if you repeatedly infringe other people's intellectual property rights, or where we are permitted or required to do so by law. If you believe your account has been terminated in error, or you want to disable or permanently delete your account, reach out to us at info@givebee.co.
            </li>
            <li>
              Content you delete may persist for a limited period of time in backup copies and will still be visible where others have shared it or downloaded it. Once a business you have tagged downloads your content, they are granted a license. If you believe a party other than the business you tagged is misusing your content within any of our services, please reach out the info@givebee.co. This paragraph will still apply even after your account is terminated or deleted.
            </li>
          </ul>
          <p>Connected Third Parties and Accounts</p>
          <p>Through our Services, users are able to connect to other platforms and third parties to improve their experience. For example, services like Plaid allow us to access transaction data from a connected financial institution in order to fulfill and reward points for purchases made at partnered Business locations.</p>
          <ul>
            <li>
              By connecting to or using one of these approved third parties, you are acknowledging that you are the rightful owner of that account. GiveBee holds the right to disconnect and discontinue any account connections if you are not the owner or for any reason under the law.
            </li>
          </ul>
          <p className="font-weight-demibold">Our Agreement and What Happens if We Disagree</p>
          <p className="font-weight-demibold">Our Agreement</p>
          <ul>
            <li>
              If any aspect of this agreement is unenforceable, the rest will remain in effect.
            </li>
            <li>
              Any amendment or waiver to our agreement must be in writing and signed by us. If we fail to enforce any aspect of this agreement, it will not be a waiver.
            </li>
            <li>
              We reserve all rights not expressly granted to you.
            </li>
          </ul>
          <p className="font-weight-demibold">Who Has Rights Under this Agreement</p>
          <ul>
            <li>
              This agreement does not give rights to any third parties.
            </li>
            <li>
              You cannot transfer your rights or obligations under this agreement without our consent.
            </li>
            <li>
              Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition, or sale of assets) or by law.
            </li>
          </ul>
          <p className="font-weight-demibold">Rewards & Points on GiveBee</p>
          <p>
            GiveBee community users are able to earn Rewards through their use of our Services. Points can be earned using our Services in the following ways:
          </p>
          <ul>
            <li>
              Purchasing items at partnered Business locations using a debit card, credit card, or other payment method that is associated with a linked financial institution verified by a third party service such as Plaid.
            </li>
            <li>
              Posting and sharing photos that generate unique photo views and unique website visits for the partnered Business you have tagged.
            </li>
            <li>
              Posting and sharing photos that are rewarded by a Business Account.
            </li>
            <li>
              Collaborating with a Business and posting your content to other social platforms.
            </li>
            <li>
              Participating in GiveBee events and contests that may take place on other platforms.
            </li>
          </ul>
          <p>
            Some of the rewarded actions above are limited to users who are Sponsored members for a Business Account. Terms and details surrounding the nature of <strong className="font-weight-bold">Sponsorships</strong> are outlined below in the Sponsorships section.
          </p>
          <p>
            Terms surrounding the earning, use, and redemption of these Rewards are contained but not limited to the following:
          </p>
          <ul>
            <li>
              Rates at which points (“points rates”) are earned can vary between GiveBee partners and are subject to change at any time.
            </li>
            <li>
              Point rates associated with a Business Account can be edited or changed by that Business or any admin within their account at any time.
            </li>
            <li>
              GiveBee has the right to suspend or terminate any Business Account, Rewards, point balances, or other data if it is deemed the associated Business Account has violated the Terms of Service herein or has used our Services unlawfully.
            </li>
            <li>
              At this time, we do not convert or transfer value of GiveBee points to fiat, digital, or other forms of currency or compensation.
            </li>
            <li>
              Falsified Rewards or redemptions may result in immediate termination of your GiveBee account.
            </li>
            <li>
              If a business terminates their GiveBee Business Account and is no longer offering rewards through our Services, we cannot guarantee any fulfillment of rewards associated with that Business or a full redemption towards any rewards. If you have any questions regarding points associated with a disconnected Business Account, please contact us at support@givebee.co
            </li>
          </ul>
          <p>
            GiveBee does not directly offer or control Rewards offered by our Business accounts. You hereby acknowledge that GiveBee does not supervise, direct, control, or monitor the Rewards and expressly disclaims any responsibility and liability for the Rewards, including but not limited to any warranty or condition of quality or fitness for a particular purpose, or compliance with any law, regulation, or code.
          </p>
          <p>
            By using the Services, you agree to comply if a reward is earned at a Business which is licensed to sell alcoholic beverages, you represent that both you and, if different, the ultimate recipient of the Reward are at least 21 years of age. Upon redemption of the Reward, the recipient shall present a government-issued identification card consistent with applicable law, evidencing proof of legal drinking age. You also agree that the Business may refuse to redeem or fulfill the redeemed alcoholic beverages if you appear to be intoxicated when receiving delivery of such products. You hereby acknowledge that GiveBee does not supervise, direct, control or monitor the Business in connection with the sale of alcoholic beverages or any other items and expressly disclaims any responsibility and liability in connection with such sale, including but not limited to any warranty or condition of quality or fitness for a particular purpose, or compliance with any law, regulation, or code.
          </p>
          <p>
            If you are Business and believe there are problems or a misuse of Rewards or rewarded behavior, please contact us at support@givebee.co.
          </p>
          <p>
            Users are responsible for paying all taxes and fees associated with any Rewards received from GiveBee.
          </p>
          <p className="font-weight-demibold">Sponsorships</p>
          <p>
            GiveBee community Users are able to be sponsored by an active Business Account on GiveBee. The terms surrounding Sponsorships and the agreement between the Business and Community User are included but not limited to the following:
          </p>
          <ul>
            <li>
              Sponsored users receive benefits primarily on GiveBee that include but are not limited to: improved point-earning rates, exclusive Rewards, automatic point earnings through engagements, and extra forms of earning points towards a Business or other GiveBee party.
            </li>
            <li>
              A Business holds the right to activate and revoke sponsorships on any User at any time.
            </li>
            <li>
              Business users hold the right to deny the redemption of a reward if they believe it is invalid or falsified. For questions or support please contact us at support@givebee.co
            </li>
            <li>
              As a Sponsored user, you agree not to defame, diminish, or falsely claim to have any relationship with our partnered Business Accounts beyond the one expressed on GiveBee. Should there be any legal dispute or class action regarding your actions in relation to a Sponsored relationship with one of our Business partners, you acknowledge that GiveBee waives any legal responsibility to intervene or participate in legal action between you and the Business Account.
            </li>
            <li>
              We reserve the right to revoke a sponsorship from any User without notice.
            </li>
          </ul>
          <p className="font-weight-demibold">Other Details on Rewarded Behaviors</p>
          <p>
            GiveBee does not track or reward engagements that occur on other platforms besides our own. For social shares, we only reward the initial share to the platform at this time. We cannot guarantee that the content was shared by our users or that the content will remain posted on the platform, even if the content or social share was rewarded by a Business Account
          </p>
          <p className="font-weight-demibold">Intellectual Property and Service Restrictions</p>
          <p>
            Subject to this Agreement, we grant you access to the GiveBee technology (the “Platform”) through our mobile application(s) (the “App”) and/or our website at www.givebee.co (the “Website”) to use our Services. If you access the Services via the App, we grant you a limited, non-exclusive and nontransferable license to: (a) download, install, and use the App for use in accordance with these Terms on a single mobile device owned or otherwise controlled by you (“Mobile Device”) strictly in accordance with this Agreement, and (b) to access, stream, download and use on such Mobile Device content and Services made available in or otherwise accessible through the App, in accordance with this Agreement. You acknowledge and agree that the App, the Website, the Platform and the Services are provided under license, and not sold, to you. You agree not to reverse engineer to decompile the Services, attempt to do so, or assist anyone in doing so.
          </p>
          <p>
            The Services contain proprietary content and information, are owned by GiveBee and/or its licensors, including our business customers, and are protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary content, information or materials other than for your permitted use of the Services or in any manner that is inconsistent with the terms of this Agreement. 
          </p>
          <p className="font-weight-demibold">Copyright Protection</p>
          <p>
            We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act. If you believe that anything on the App or Site infringes on a copyright that you own or control, you may file a notification of such infringement by emailing info@givebee.co.
          </p>
          <p className="font-weight-demibold">Compliance with Laws and Policies</p>
          <p>
            You agree that you and all Content submitted by you or on your behalf will fully comply with applicable laws, rules, regulations, and industry guidelines, including but not limited to the Federal Trade Commission Guides Concerning the Use of Endorsements and Testimonials in Advertising, 16 CFR Part 255 (collectively, “Applicable Laws”), and that you will disclose in every post that mentions or features a Business that you have received payments from the Business as described in this Agreement. You will cooperate with us in removing or modifying any inappropriate Content relating to the Business from your blog, website, or your accounts on social networking websites, such as Instagram, Facebook, Pinterest, or Snapchat (“Social Media Provider”) or any other media over which you have control. To the extent you are not in compliance with the terms required under this Agreement (without limiting any remedies that may be available to us and a Business in law or equity) or are otherwise requested by us for any reason, you will promptly remove and take down any Content we deem inappropriate for any reason.
          </p>
          <p className="font-weight-demibold">GiveBee Communication</p>
          <p>
            By establishing an Account with GiveBee, you agree to receive communications from us, including via email, text messages, and push notifications. If you wish to not receive any promotional emails from GiveBee please email support@givebee.co or follow the unsubscribe options in the promotional emails. You may not be able to unsubscribe from receiving essential Service-related and Account-related informational (transactional) emails from GiveBee. If you do wish to unsubscribe from such emails, please send your request to support@givebee.co with the subject line: “Unsubscribe from GiveBee Transactional Emails.” Please note that unsubscribing from such emails will impact your use and enjoyment of GiveBee Platform, Services, Website and App and will impact your communication with us and/or Businesses. You can opt out of receiving push notifications through your device settings. Please note that opting out of receiving push notifications may impact your use of our Services. You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Services (“Submissions”), provided by you to us are non-confidential and we will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
          </p>
          <p className="font-weight-demibold">Confidentiality</p>
          <p>
            Any information disclosed to you through your access to the Services related in any way to GiveBee and our business and the business of our customers is deemed confidential and proprietary, including but not limited to any Reward. You agree to hold such information in the strictest of confidence. Such Confidential Information shall include but not be limited to GiveBee’ Platform, App, identity of our customers, including any Business, names, phone numbers, addresses, e-mail addresses, deals, pricing information and other information regarding a Business and all other information that you may receive (which GiveBee reserves the right to provide in its sole discretion to any Account holder). You agree to use such methods to reasonably maintain the confidentiality of the information as you would to protect your own. During the Term of this Agreement, you will also keep confidential and not disclose the terms of this Agreement or to use any such information for your own purposes.
          </p>
          <p className="font-weight-demibold">Termination</p>
          <p>
            You may close your account and terminate your relationship with us without cost, but you will remain liable for all obligations related to your GiveBee Account even after the account is closed.
          </p>
          <p>
            In addition, GiveBee may terminate this Agreement and/or deactivate your Account in the event that (1) you are no longer deemed to be an appropriate GiveBee Account holder according to GiveBee’s sole and absolute discretion, (2) it’s GiveBee’s understanding that such action is necessary to protect the Businesses you’ve tagged, other GiveBee Users, or GiveBee’s Platform and/or Services, or (3) a Restaurant has requested such action. GiveBee will make a reasonable effort to give you a notice of such deactivation and may offer an opportunity to attempt to cure the issue prior to permanently terminating the Agreement. 
          </p>
          <p className="font-weight-demibold">Assignment</p>
          <p>
            You shall have no right to assign this Agreement or any benefits or obligation hereunder to any other party or legal entity. Any attempted assignment shall be void.
          </p>
          <p className="font-weight-demibold">Modification</p>
          <p>
            We will revise these Terms from time to time. If you do not agree with certain changes, please discontinue your use of the Services. By continuing to use them after the Terms have been updated, you agree to be bound by the revised Terms.
          </p>
          <p className="font-weight-demibold">Non-Disparagement</p>
          <p>
            During the Term and for five (5) years after that, you agree that you will not disparage GiveBee or any of its officers, directors or employees or otherwise take any action that reasonably be expected to adversely affect GiveBee’s reputation. For purposes of this Agreement, “disparage” shall mean any negative statement, whether written or oral, about GiveBee or any of its officers, directors or employees.
          </p>
          <p className="font-weight-demibold">Dispute Resolution and Binding Arbitration</p>
          <p>
            You and GiveBee agree to use your best efforts to settle amicably all disputes arising out of this Agreement. Should the Parties be unable to resolve any dispute, then the matter shall be referred to arbitration. The arbitration shall be held in Cook County, Illinois according to the Rules of the American Arbitration Association.
          </p>
          <p className="font-weight-demibold">Governing Law</p>
          <p>
            This Agreement shall be governed by the law of the State of Illinois, without respect to its conflicts of laws principles. We each agree to submit to the personal jurisdiction of a state or federal court located in Chicago, Illinois for any actions not subject to Dispute Resolution and Arbitration provisions as set forth in this Agreement.
          </p>
          <p>
            This Agreement along with the Privacy Policy constitutes the entire agreement between you and us and supersedes any prior agreements between you and us with respect to the subject matter herein. Our failure to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision.
          </p>
          <p className="font-weight-demibold">Contact</p>
          <p>
            If you have questions, complaints, or claims with respect to the Site or App, please contact your legal counsel or contact us at: support@givebee.co.
          </p>
          <br />
        </Col>
      </Row>
    </Container>
  );
}

export default TermsPage;
