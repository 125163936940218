import React, { useEffect, useState } from "react";
import { currentOnboardStatus } from "./OnboardingFunctions";

import "./OnboardingNavMenu.css"

const OnboardingProgressModule: React.FC<any> = (props:any) => {

  const { currentBusiness } = props;
  const [progress, setProgress] = useState(0);

  const setModuleProgress = (currentBusiness:any) => {
    if (currentBusiness) {
      const { moreInfoSet, rewardsSet, bothSubscriptionsActive } = currentOnboardStatus(currentBusiness);
      let progressAmount = 10;                    // Default 10% for sign up
      if (moreInfoSet) { progressAmount += 20; }
      if (rewardsSet) { progressAmount += 20; }
      if (bothSubscriptionsActive) { progressAmount += 50; }
      setProgress(progressAmount);
    }
  }

  useEffect(() => {
    setModuleProgress(currentBusiness);
  }, [props.currentBusiness]);

  return (
    <div className="layout-nav-side-content-wrapper">
      <h2 className="giveBeeSetupHeader ml-2">GiveBee setup</h2>
      <div className="progress-bar ml-2">
        <div className="progress-filler" style={{ width: `${progress}%` }}>
        </div>
      </div>
      <h4 className="giveBeeSetupSubHeader mt-2 ml-2">{progress}% complete</h4>
      <p className="font-weight-demibold mt-3">Questions? Text us at</p>
      <p className="font-weight-demibold phoneNumber">(847) 856-9342</p>
    </div>
  );
}

export default OnboardingProgressModule;
