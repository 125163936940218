import React, { useState, useEffect } from "react";
import { firebaseAuth, db } from "../../firebase";
import { signUpWithEmailAndPassword, getUserTypeInfo, logout } from "../../helpers/auth";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import * as pathNames from "../../constants/PathNames";

import "./BusinessSignUp.css";
import logo from "../../assets/images/GiveBee_201.png";

const userSessionKey = "userId";

interface BusinessSignUpProps extends RouteComponentProps {}

const BusinessSignUp: React.FC<BusinessSignUpProps> = ({ history }) => {

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordNotification, setPasswordNotification] =  useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [error, setError] = useState<Error|undefined>(undefined);

  useEffect(() => {
    firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem(userSessionKey, user.uid);
        getUserTypeInfo(user.uid)
          .then((userType) => {
            if (userType === "existingBusinessUser") {
              // If there is already a user logged and filled out the moreInfo in go straight to the account page
              db.collection("businesses").doc(user.uid).get()
                .then(docSnapshot => {

                  const userData = docSnapshot.data();
                  if (userData === undefined) return;

                  if (userData.address1.length > 1) {
                    history.push(pathNames.businessCustomersPath);
                  } else {
                    history.push(pathNames.businessOnboardingPath);
                  }
                })
                .catch((err) => {
                  // Error occurred in fetching business
                });
            } else {
              // We only want one auth session at a time
              // We assume if someone comes to this page, they are not a poster user
              // They can re-sign in later if they scan to post
              logout()
            }
          })
          .catch((error) => {
            console.log("Error ocurred in checking current user");
            console.error(error);
          })
      }
    });

  }, []);

  const onSubmit = (event: any) => {
    // Store businessName in a cookie for next page
    document.cookie = "businessName=" + businessName + "; path=/";
    setSignUpLoading(true);
    // Trigger Firestore func in auth.js
    signUpWithEmailAndPassword(email, password)
      .then(() => {
        const currentUser = firebaseAuth().currentUser;
        if (!currentUser) return;

        const businessId = currentUser.uid;
        // Create Unix timestamp
        const unixTimestamp = (Math.round((new Date()).getTime() / 1000));

        let newBusiness = {
          active: false,
          businessId: businessId,
          businessName: businessName,
          created_at: unixTimestamp,
          fullName: fullName,
          moreInfoComplete: false,
          phone: phone
        };

        let newBusinessContact = {
          email: email
        };

        // Add a new business to the `businesses` collection
        // Doc id uses the current auth id generated above
        db.collection("businesses")
          .doc(businessId)
          .set(newBusiness, { merge: true })
          .then(() => {
            // On successful creation also make a userContact
            db.collection("businessContacts")
              .doc(businessId)
              .set(newBusinessContact, { merge: true})
              .then(() => {
                console.log("Successfully created new business user!");
                // On successful sign up, go to the moreinfo page
                history.push(pathNames.businessOnboardingPath);
                setSignUpLoading(false);
              }).catch((error) => {
                // Error in creation of 
                console.error(error);
                setSignUpLoading(false);
              });
        });
      })
      .catch(error => {
        console.error(error);
        setError(error);
        setSignUpLoading(false);
      });
    event.preventDefault();
  };
    
  const isInvalid = password !== passwordConfirmation ||
    password.length < 8 ||
    email === "" ||
    fullName === "" ||
    businessName === "" ||
    phone === "";

  return (
    <Container className="businessSignUp">
      <img
        src={logo}
        alt="GiveBee - Get the people buzzin'!"
        className="logoNorm"
        onClick={() => {
          history.push("/")
        }}
    />
    <Row>
      <Col>
        <h3 className="font-weight-demibold mt-3">
          It's time social media began creating real community.
        </h3>
      </Col>
    </Row>
    <Row>
      <Col>
        <p className="subText">
          Get social media content straight from your customers and watch loyal customers become lifelong advocates!
        </p>
      </Col>
    </Row>
    <br />
    <div className="form">
      <form onSubmit={onSubmit}>
        <Row>
          <Col>
            <input
              className="form-field form-field-auth"
              name="fullName"
              value={fullName}
              onChange={event => setFullName(event.target.value)}
              type="text"
              placeholder="Full name"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              className="form-field form-field-auth"
              name="email"
              required={true}
              type="email"
              onChange={event => setEmail(event.target.value)}
              value={email}
              placeholder="Work email address"
            />
          </Col>
        </Row>
        <Row>
          <Col>
          <input
            className="form-field form-field-auth"
            name="businessName"
            value={businessName}
            onChange={event => setBusinessName(event.target.value)}
            type="text"
            placeholder="Company name"
          />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
            className="form-field form-field-auth"
            name="phone"
            value={phone}
            onChange={event => setPhone(event.target.value)}
            type="phone"
            placeholder="Company phone"
          />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              className="form-field form-field-auth"
              name="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              type="password"
              placeholder="Password"
            />
            <div hidden={password.length >= 8 || !passwordNotification}>
              <p className="passwordNotification">Password must be at least 8 characters long</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              className="form-field form-field-auth"
              name="passwordConfirmation"
              value={passwordConfirmation}
              onChange={event => setPasswordConfirmation(event.target.value)}
              type="password"
              placeholder="Confirm password"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Spinner hidden={!signUpLoading} animation="border" />
            <button className="signUpButton" disabled={isInvalid} type="submit">
              Sign Up
            </button>
          </Col>
        </Row>
        { (error !== undefined) ? <p>{error.message}</p> : <p/> }
      </form>
    </div>
  </Container>
  );
}

export default BusinessSignUp;
