import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col, Nav, Form, FormControl, InputGroup } from "react-bootstrap";
import { db } from "../../../firebase";
import Burger from "../../nav/Burger";
import Footer from "../../nav/Footer";
import EmailInput from "../EmailInput";

import logo from "../../../assets/images/GiveBee_201.png";
import homeAsset from "../../../assets/images/home-page-asset.png";

import "../HomePage.css";
import "./BusinessLandingPage.css";

var ReactRotatingText = require("react-rotating-text");

interface BusinessLandingPageProps extends RouteComponentProps {}

const BusinessLandingPage: React.FC<BusinessLandingPageProps> = ({ history }) => {
  
  const [email, setEmail] = useState("");
  const [bottomEmail, setBottomEmail] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [error, setErrorMessage] = useState("");

  const handleSubmittedEmail = (event:any) => {

    setSubmissionLoading(true);
    event.preventDefault();

    const newWaitlistUser = {
      email: email,
    };

    db.collection("businessWaitlist").add(newWaitlistUser)
      .then(() => {
        setSubmissionLoading(false);
      })
      .catch((error:any) => {
        setSubmissionLoading(false);
        setErrorMessage(`Error updating business info: ${error.message}`);
      });
  };

  const submitButtonDisabled = () => {
    return submissionLoading || email.length < 5;
  }

  return (
    <>
      <Nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logo navbar-brand"
          onClick={() => {
            history.push("/")
          }}
        />
        <Burger history={ history } />
      </Nav>
      <Row className="row-lightYellow row-top business-landing">
        <Col xs={12} md={6} className="column-regular my-auto">
          <h1 className="top-line">Collect local, user-generated content for your</h1>
          <h1><ReactRotatingText items={["social media clients", "online menu", "Instagram blog"]} id="rotating-title"/></h1>
          <br />
          <h4>Join dozens of businesses saving time & money on weekly content creation.</h4>
          <EmailInput />
        </Col>
        
        <Col xs={12} md={4} className="centerContentX my-auto">
          <img id="appScreenImg" alt="" src={ homeAsset } />
        </Col>
      </Row>
      <Row className="centerContentX row-about">
        <Col className="home-info-col">
          <h2 className="mb-3">Get weekly content for your local business.</h2>
          <h5>Stop wasting time & money on photo shoots.</h5>
          <h5>Stop hoping for customers to tag you on social media.</h5>
          <h5>Get amazing, local content starting at $5 per photo.</h5>
        </Col>
      </Row>
      <Row className="centerContentX">
        <Col className="home-info-col">
          <h2 className="mb-3">Join the waitlist today</h2>
          <h5>No annoying emails. Just a notification when we're ready to give you amazing content!</h5>
          <Row id="emailFormFieldBottom">
            <Col>
              <EmailInput  />
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default BusinessLandingPage;