// React Components and Other Libraries
import * as GFK from '../../../global/firestoreKeys';
import React, { useState, useEffect } from "react";
import { functions } from "../../../firebase";
import { Row, Col, Form, FormGroup, Spinner } from "react-bootstrap";
import { LazyImage } from "../../../helpers/LazyImage";
import { PhotoMetric } from "../../../constants/PointsClassObjects";
import { convertGiveBeeIntToUSDString } from "../../../helpers/PointsAndMoneyConversions";

// UI CSS
import arrowWhite from "../../../assets/images/arrow-white.svg";
import coinIcon from "../../../assets/images/coin-icon.svg";
import igActive from "../../../assets/images/ig-active.svg";
import igInactive from "../../../assets/images/ig-inactive.svg";
import igStoriesInactive from "../../../assets/images/igStories-inactive.svg";
import igStoriesActive from "../../../assets/images/igStories-active.svg";
import fbActive from "../../../assets/images/fb-active.svg";
import fbInactive from "../../../assets/images/fb-inactive.svg";
import fbStoriesInactive from "../../../assets/images/fbStories-inactive.svg";
import fbStoriesActive from "../../../assets/images/fbStories-active.svg";
import fbMessengerInactive from "../../../assets/images/fbMessenger-inactive.svg";
import fbMessengerActive from "../../../assets/images/fbMessenger-active.svg";
import xIcon from "../../../assets/images/X-icon-dark.svg";

const ShareRequestModal: React.FC<any> = (props:any) => {

  const {
    contentArray,
    photoToNudge,
    setPhotoToNudge,
  } = props;

  const platformKeys = [
    GFK.PlatformIds.ig,
    GFK.PlatformIds.igStories,
    GFK.PlatformIds.fb,
    GFK.PlatformIds.fbStories,
    GFK.PlatformIds.fbMessenger,
  ]

  const [platformsChecked, setPlatformsChecked] = useState<any>({});
  const [totalPoints, setTotalPoints] = useState(0);

  const [sendingRequest, setSendingRequest] = useState(false);
  const [sentRequestSuccess, setSendingRequestSuccess] = useState(false);
  const [sendingRequestErr, setSendingRequestErr] = useState("");

  useEffect(() => {
    if (photoToNudge !== undefined) {
      setModal();
    }
  }, []);

  function setModal() {
    const element = document.getElementById("requestOverlay");
    if (element !== null) {
      element.style.display = "block";
    }
  }

  const photoUserName = (photo:PhotoMetric) => {
    const firstName = (photo.userInfo.firstName ? photo.userInfo.firstName : "");
    return (firstName.length > 1) ? firstName : "your customer";
  }

  const platformIcon = (platform:string, active:boolean) => {
    switch (platform) {
      case GFK.PlatformIds.ig:
        return (active) ? igActive : igInactive;
      case GFK.PlatformIds.igStories:
        return (active) ? igStoriesActive : igStoriesInactive;
      case GFK.PlatformIds.fb:
        return (active) ? fbActive : fbInactive;
      case GFK.PlatformIds.fbStories:
        return (active) ? fbStoriesActive : fbStoriesInactive;
      case GFK.PlatformIds.fbMessenger:
        return (active) ? fbMessengerActive : fbMessengerInactive;
      default:
        return "";
    }
  }

  const platformName = (platform:string) => {
    switch (platform) {
      case GFK.PlatformIds.ig:
        return "Instagram";
      case GFK.PlatformIds.igStories:
        return "IG Stories";
      case GFK.PlatformIds.fb:
        return "Facebook";
      case GFK.PlatformIds.fbStories:
        return "FB Stories";
      case GFK.PlatformIds.fbMessenger:
        return "Messenger";
      default:
        return "";
    }
  }

  // Function for getting a platform's initial value
  const platformInitialPointValue = (name:string) => {
    switch (name) {
      case GFK.PlatformIds.ig:
        return 100;
      case GFK.PlatformIds.igStories:
        return 50;
      case GFK.PlatformIds.fb:
        return 100;
      case GFK.PlatformIds.fbStories:
        return 50;
      case GFK.PlatformIds.fbMessenger:
        return 30;
      default:
        return 25;
    }
  }

  // Current Platform points
  const getCurrentPlatformPoints = (platform:string) => {
    if (platformsChecked[platform]) {
      // This platform is currently being incremented
      // Value will change with state change
      return platformsChecked[platform];
    } else if (businessRequestedPlatforms().includes(platform)) {
      // The platform key has been requested and should exits in dict
      const shareRequestsDict = photoToNudge.businessShareRequests;
      return shareRequestsDict[platform].potentialClientPointsAmount;
    } else {
      return 0;
    }
  }

  // Flag variable/function
  const businessRequestedPlatforms = () => {
    const shareRequests = photoToNudge.businessShareRequests;
    return (shareRequests) ? Object.keys(shareRequests) : [];
  }

  // Flag variable/function
  const creatorSharedToPlatforms = () => {
    const sharedToTimestamps = photoToNudge.creatorSharedTo;
    return (sharedToTimestamps) ? Object.keys(sharedToTimestamps) : [];
  }

  // Hide the checkbox if platform is already shared or requested
  const shouldHideCheckbox = (platform:string) => {
    return (sentRequestSuccess || creatorSharedToPlatforms().includes(`${platform}_shared_at`) || businessRequestedPlatforms().includes(`${platform}`));
  }

  // Hide the "sent!" label if the platform is already shared or was never even sent
  const shouldShowSentLabel = (platform:string) => {
    return (businessRequestedPlatforms().includes(`${platform}`));
  }

  // Show the "done!" label if the platform is shared to
  const shouldShowDoneLabel = (platform:string) => {
    return (creatorSharedToPlatforms().includes(`${platform}_shared_at`))
  }

  // Show the points label that will only show if:
  // - a request is sent and pending
  // - a creator has shared
  const showPointsLabel = (platform:string) => {
    const sharedOrPending = creatorSharedToPlatforms().includes(`${platform}_shared_at`) || businessRequestedPlatforms().includes(`${platform}`);
    const currentPlatformChecks = Object.keys(platformsChecked);
    return sharedOrPending || currentPlatformChecks.includes(platform);
  }

  const showIncrementButtons = (platform:string) => {
    const currentPlatformChecks = Object.keys(platformsChecked);
    return (currentPlatformChecks.includes(platform));
  }

  // Function that is activated as a business chooses a platform they want a creator to share to
  const handleCheckPlatform = (event:any) => {

    setSendingRequestErr("");
    const values = {...platformsChecked}

    if (values.hasOwnProperty(event.target.name)) {
      // Grab current points for platform being unchecked OUT
      const pointsMeantToSend = values[event.target.name];
      const newPoints = totalPoints - pointsMeantToSend;
      setTotalPoints(newPoints);
      // Update point increments dict
      delete values[event.target.name];
    } else {
      // Grab default points for platform being checked IN
      const pointsMeantToSend = platformInitialPointValue(event.target.name);
      const newPoints = totalPoints + pointsMeantToSend;
      setTotalPoints(newPoints);
      // Update point increments dict
      values[event.target.name] = platformInitialPointValue(event.target.name);
    }
    setPlatformsChecked(values);
  }

  // Up arrow was clicked
  function handlePointIncrement(event:any, platform:string) {

    event.preventDefault();

    const values = {...platformsChecked};
    values[platform] += 1;
    setPlatformsChecked(() => values);

    const newPoints = totalPoints + 1;
    setTotalPoints(newPoints);
  }

  // Down arrow was clicked
  function handlePointDecrement(event:any, platform:string) {

    event.preventDefault();

    const values = {...platformsChecked};
    values[platform] -= 1;
    setPlatformsChecked(() => values);

    const newPoints = totalPoints - 1;
    setTotalPoints(newPoints);
  }

  /**
   * 
   * @param photo respective photoMetric doc that is about to receive reward points
   * 
   * Triggered when the business chooses the platforms the user can share to and presses send.
   */
  const triggerSendingBusinessShareRequest = (event:any) => {

    event.preventDefault();

    const platformNewRequestKeys = Object.keys(platformsChecked);
    if (platformNewRequestKeys.length > 0) {

      setSendingRequest(true);

      // Get current timestamp and PhotoMetric data
      const newDataForRequest:any = {
        ...photoToNudge,
      };
      // Create the newBusinessShareRequests dict here
      // Old businessShareRequests dict is in photoToNudge if old requests were sent
      const newBusinessShareRequests:any = {};
      platformNewRequestKeys.forEach((platformKey:any) => {
        const potentialPoints = platformsChecked[platformKey]
        newBusinessShareRequests[platformKey] = {
          potentialClientPointsAmount: potentialPoints,
          potentialPointsAmount: potentialPoints * 100,
        }
      });
      newDataForRequest.newBusinessShareRequests = newBusinessShareRequests;

      var businessShareRequest = functions.httpsCallable("businessRequestedSocialShare");
      businessShareRequest(newDataForRequest)
        .then((result) => {
          const resultData = result.data;
          contentArray.forEach((currPhoto:PhotoMetric) => {
            if (photoToNudge.id === currPhoto.id) {
              // TODO: Figure out cleaner way to sync objects
              photoToNudge.business_rewarded_photo = currPhoto.business_rewarded_photo = resultData.business_rewarded_photo;
              photoToNudge.businessShareRequests = currPhoto.businessShareRequests = resultData.businessShareRequests;
              photoToNudge.businessRequestTimestamps = currPhoto.businessRequestTimestamps = resultData.businessRequestTimestamps;
              photoToNudge.creatorSharedTo = currPhoto.creatorSharedTo = resultData.creatorSharedTo;
              photoToNudge.totalPhotoViews = currPhoto.totalPhotoViews = resultData.totalPhotoViews;
              photoToNudge.totalWebsiteVisits = currPhoto.totalWebsiteVisits = resultData.totalWebsiteVisits;
              photoToNudge.totalCharge = currPhoto.totalCharge = resultData.totalCharge;
            }
          });
          setSendingRequest(false);
          setSendingRequestSuccess(true);
        })
        .catch((err) => {
          setSendingRequestErr(err.message);
          setSendingRequest(false);
        });
    }
  }

  // Each available platform distributed evenly across the row
  const platformFormGroups = platformKeys.map((platform, index) => (
    <Col key={`${platform}~${index}`}>
      <FormGroup className="platformFormGroup">
        <img
          className="social-icon"
          alt=""
          src={ platformIcon(platform, creatorSharedToPlatforms().includes(`${platform}_shared_at`)) }
        />
        <br />
        <label
          className="platformLabel mr-1"
        >
          { platformName(platform) }
        </label>
        <input
          name={platform}
          type="checkbox"
          className="inline"
          disabled={ sendingRequest }
          hidden={ shouldHideCheckbox(platform) }
          onChange={(event) => handleCheckPlatform(event) }
        />
        <div className="sharePointsDiv">
          { showPointsLabel(platform)
          && <p className="platformLabel">
            { getCurrentPlatformPoints(platform) }{" "}
            <img className="regularIcon mb-1" alt="" src={coinIcon}/>
          </p> }
          { showIncrementButtons(platform)
          && <div className="pointsIncrementsDiv" hidden={ sentRequestSuccess }>
            <button
              className="mr-1"
              disabled={ platformsChecked[platform] <= 10 }
              onClick={(event) => handlePointDecrement(event, platform) }
            >
              <img className="regularIcon my-auto" alt="" src={arrowWhite} />
            </button>
            <button
              className="ml-1"
              disabled={ platformsChecked[platform] >= 1000 }
              onClick={ (event) => handlePointIncrement(event, platform) }
            >
              <img className="regularIcon rotateImg180 my-auto" alt="" src={arrowWhite} />
            </button>
          </div> }
        </div>
        { shouldShowSentLabel(platform) && <p className="sentLabel">pending...</p> }
        { shouldShowDoneLabel(platform) && <p className="doneLabel">done!</p> }
      </FormGroup>
    </Col>
  ));

  return (
    <Row className="rewardContentModal">
      <Row>
        <Col className="text-right">
          <button
            className="button-icon mr-3"
            onClick={() => setPhotoToNudge(undefined) }
          >
            <img className="regularIcon ml-3 mt-3" alt="exit" src={ xIcon } />
          </button>
        </Col>
      </Row>
      <Col>
        <Row className="mt-3">
          <Col>
            <LazyImage
              className="rewardContentImage"
              alt="photo was deleted :("
              src={photoToNudge.url}
            />
            <h5 className="nudgeNameText mt-1">Nudge {photoUserName(photoToNudge)} to share this photo on:</h5>
          </Col>
        </Row>
        <Row className="platformsRow centerContentY mt-3">
          <Col>
            <Form onSubmit={(event:any) => triggerSendingBusinessShareRequest(event) }>
              <Row>
                { platformFormGroups }
              </Row>
              <Row className="mt-3">
                <Col>
                  <p>If this user earns all{" "}
                  <strong>{totalPoints} <img alt="" src={coinIcon} className="regularIcon mb-1" /></strong>{" "}
                  that you are offering, an additional{" "}
                  <strong>{convertGiveBeeIntToUSDString(totalPoints * 100) } charge</strong>{" "}
                  will be added to this month's invoice.
                  </p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="mainPointsButtonsDiv" hidden={ sentRequestSuccess }>
                    <button
                      className="button-link mr-3"
                      onClick={() => { setPhotoToNudge(undefined) }}
                    >
                      Cancel
                    </button>
                    <button
                      className="submit-button ml-3"
                      disabled={ (Object.keys(platformsChecked)).length <= 0}
                    >
                      <div>Send Nudge!</div>
                    </button>
                  </div>
                  <div className="donePointsButtonsDiv" hidden={ !sentRequestSuccess }>
                    <p className="doneDiv font-weight-demibold mr-2 ">
                      Hooray! Nudge sent! &#x1F973;
                    </p>
                    <button
                      className="doneDiv ml-2"
                      onClick={ () => { setPhotoToNudge(undefined); } }
                    >
                      <div>Done</div>
                    </button>
                  </div>
                  <Spinner animation="border" className="mt-1" hidden={!sendingRequest}  />
                </Col>
              </Row>
            </Form>
            <p className="mt-2" hidden={sendingRequestErr === ""}>Error: {sendingRequestErr} </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );

}

export default ShareRequestModal;