import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { functions } from "../../firebase";
import { Row, Col, Nav, InputGroup, Form, FormControl } from "react-bootstrap";

import "./NoniOSLandingPage.css";
import logo from "../../assets/images/GiveBee_201.png";
import androidGroup from "../../assets/images/andorid-image-group.png";
import appStoreIcon from "../../assets/images/download-on-app-store.svg";

interface NoniOSLandingPageProps extends RouteComponentProps {}

const NoniOSLandingPage: React.FC<NoniOSLandingPageProps> = ({ history, location }) => {

  const [email, setEmail] = useState("");
  const [codesArray, setCodeArray] = useState<Array<string>>([]);

  useEffect(() => {
    initialSetCode()
  }, []);

  function initialSetCode() {
      const params = new URLSearchParams(location.search);
      const initParam = params.get("sponsorshipCode");
      const codeParam = (initParam !== null) ? initParam : "";
      setCodeArray([codeParam]);
  }

  function handleEmailFill(e:any) {
    setEmail(e);
  }

  const handleSubmit = (event:any) => {

    const userWaitlistObj = {
      codes: codesArray,
      email: email,
    };

    var addToUsersWaitlist = functions.httpsCallable("createWaitlistUser");
    addToUsersWaitlist(userWaitlistObj)
      .then((result:any) => {
        console.log("Success:", result);
      })
      .catch((err:any) => {
        const code = err.code;
        const message = err.message;
        const details = err.details;
        console.error("Error", code, message, details);
      });
  };
  
  return (
    <div>
      <Nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logo navbar-brand"
          onClick={() => {
            history.push("/")
          }}
        />
      </Nav>
      <Row className="backgroundDiv">
        <Col>
          <br/>
          <br/>
          <Row>
            <Col xs={12} md={6} className="centerCol">
              <Row>
                <Col>
                  <h2 className="customH2">Sorry, we are not available on Android yet.</h2>
                  <img id="androidImg" src={androidGroup} alt="android-group"/>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="centerCol">
              <Row className="mt-3">
                <Col>
                  <h2 className="customH2">Join our waitlist to know when we expand!</h2>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form id="submitForm" onSubmit={handleSubmit} >
                    <InputGroup className="mb-3">
                      <FormControl
                        type="email"
                        value={email}
                        placeholder="Your fav email address"
                        onChange={(event:any) => handleEmailFill(event.target.value)}
                      />
                      <InputGroup.Append>
                        <button id="submitButton" type="submit">
                          Send it!
                        </button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>
                  <Row>
                    <Col>
                      <a href="https://apps.apple.com/us/app/givebee/id1394721571">
                      <img id="appStoreIcon" src={appStoreIcon} alt="AppStore" />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default NoniOSLandingPage;
