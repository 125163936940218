import React, { useState } from "react";
import { functions } from "../../firebase";
import { Row, Col, InputGroup, Form, FormControl, Dropdown, SplitButton, ButtonGroup, Spinner } from "react-bootstrap";

const EmailInput: React.FC<any> = (props:any) => {

  const [emailAddress, setEmailAddress] = useState("");

  const [subimittingForm, setSubmittingForm] = useState(false);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [submissionError, setSubmissionError] = useState("");


  const validateEmail = () => {    

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    const validEmail = re.test(emailAddress);

    if (!validEmail) {
      setSubmissionError("Please enter a valid email");
    }

    return validEmail;
  }

  const emailIsChanging = (value:string) => {
    setSuccessfulSubmission(false);   // Just to clear any success text
    setEmailAddress(value);
  }

  const emailSubmissionIsInvalid = () => {
    return emailAddress === "" || !validateEmail();
  }

  const handleSubmittedEmail = async(event:any) => {

    setSubmissionError("");
    setSubmittingForm(true);
    event.preventDefault();

    if (emailSubmissionIsInvalid()) {
      // Error is written to HTML in the function above
      return;
    }

    const businessWaitlistObj = {
      email: emailAddress,
    };

    var addBusinessToWaitlist = functions.httpsCallable("createWaitlistedBusiness");
    addBusinessToWaitlist(businessWaitlistObj)
      .then((result:any) => {
        setSubmittingForm(false);
        setSuccessfulSubmission(true);
        setEmailAddress("");
      })
      .catch((err:any) => {
        const code = err.code;
        const message = err.message;
        const details = err.details;
        setSubmittingForm(false);
        setSubmissionError("Sorry, an error took place. Please try again later.");
      });
  };

  return (
    <>
    <div >
      <Form onSubmit={(e:any) => handleSubmittedEmail(e) }>
        <InputGroup>
          <FormControl
            className="form-field form-field-auth"
            name="email"
            value={ emailAddress }
            onChange={(changeEvent) => emailIsChanging(changeEvent.target.value) }
            type="email"
            placeholder="Email"
          />
          <InputGroup.Append>
            <button className="submitEmailButton" disabled={ subimittingForm }>
              Submit
            </button>
          </InputGroup.Append>
        </InputGroup>
        <br />
        { submissionError && <p>{ submissionError }</p> }
      </Form>
    </div>
    <h5 className="businessesH5" hidden={ !successfulSubmission }>Thank you! &#x1F603; We'll be in touch soon!</h5>
    </>
  )
}

export default EmailInput;