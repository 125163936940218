import React, { useState } from "react";
import { Row, Col, Form, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { functions } from "../../../firebase";
import { 
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";

// UI and CSS imports
import "../../businessAuth/BusinessSignUp.css";
import "./CheckoutForm.css";
import stripeLogo from "../../../assets/images/powered_by_stripe.svg";

const CheckoutForm: React.FC<any> = ({ currentBusiness }) => {

  const [cardholder, setCardholder] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [errorString, setErrorString] = useState<string|undefined>("");

  // Stripe constants
  const stripe = useStripe();
  const elements = useElements();

  /**
   * 
   * @param event payment form submission event
   */
  const handleSubmit = async (event:any) => {
    // Block native form submission
    event.preventDefault();
    setPaymentLoading(true);

    try {
      if (!stripe || !elements) {
        setErrorString("Error setting payment method: Please wait and try again in a minute.");
        setPaymentLoading(false);
        return;
      }
      // Get a reference to a mounted CardElements. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = (elements.getElement(CardNumberElement) as StripeCardNumberElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholder,
          address: {
            postal_code: postalCode,
          },
        }
      });

      if (error) {
        setPaymentLoading(false);
        setErrorString(`Error setting payment method: ${error.message}`);
      }

      // Begin process of creating subscription
      createSubscriptions(paymentMethod);

    } catch (error) {
      setErrorString(`Error setting payment method: ${error.message}`);
    }
  };
  
  /**
   * 
   * @param paymentMethod   newly created Stripe Payment Method object
   * 
   * Call the createCardsAndAdsSubscriptions.httpsCallable function
   */
  const createSubscriptions = (paymentMethod:any) => {

    try {
      // Begin the logic for creating subscription
      if (!currentBusiness.id || !paymentMethod) {
        setErrorString("Essential data is undefined, please refresh session and try again later.");
        return;
      }

      const subscriptionObject = {
        businessId: currentBusiness.id,
        paymentMethodId: paymentMethod.id,
      };

      var createSubscriptionsCallable = functions.httpsCallable("activateAdvertisingSubscription");
      createSubscriptionsCallable(subscriptionObject)
        .then((result:any) => {
          setPaymentLoading(false);
        })
        .catch((funcError:Error) => {
          setPaymentLoading(false);
          setErrorString(`Error creating subscription: ${funcError.message}. Please wait and try again in a minute.`);
        });
    } catch (err) {
      setErrorString(`Error creating subscription: ${err.message}`);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#f5a623",
        color: "#443833",
        fontWeight: "450",
        fontFamily: "Avenir Next",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#c2c2c2",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <Form id="checkoutForm" onSubmit={handleSubmit}>
      <h4 className="pt-3">Enter your payment details.</h4>
      <Row className="mt-3">
        <Col>
        <p className="text-left font-weight-demibold">Name on Card</p>
        <InputGroup>
          <FormControl
            type="text"
            required={true}
            id="cardholderElement"
            className="form-field checkout-form-field"
            placeholder="Buzzy Buzz III"
            value={cardholder}
            onChange={(e:any) => setCardholder(e.target.value) }
          />
        </InputGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <p className="text-left font-weight-demibold">Card Details</p>
          <CardNumberElement
            id="cardNumberElement"
            className="form-field checkout-form-field"
            options={CARD_ELEMENT_OPTIONS}
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <CardExpiryElement
            id="cardExpDateElement"
            className="form-field checkout-form-field"
            options={CARD_ELEMENT_OPTIONS}
          />
        </Col>
        <Col>
          <CardCvcElement
            id="cardCvcElement"
            className="form-field checkout-form-field"
            options={CARD_ELEMENT_OPTIONS}
          />
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              pattern="[0-9]{5}"
              required={true}
              id="postalCodeElement"
              className="form-field checkout-form-field"
              placeholder="Postal Code"
              value={postalCode}
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value) }
            />
          </InputGroup>
        </Col>
      </Row>
      <Spinner hidden={!paymentLoading} animation="border" />
      <button id="paymentButton" disabled={ !stripe || paymentLoading }>Add Payment Method</button>
      <img
          src={stripeLogo}
          alt="powered-by-stripe"
       />
      {errorString && <p>{errorString}</p>}
    </Form>
  );
}

export default CheckoutForm;
