import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../../assets/images/GiveBee_201.png";

import "../homePage/HomePage.css";

interface SupportPageProps extends RouteComponentProps {}

const SupportPage: React.FC<SupportPageProps> = ({ history }) => {

    return (
      <Container className="businessSignUp">
        <img
          src={logo}
          alt="GiveBee - Get the people buzzin'!"
          className="logoNorm"
          onClick={() => {
            history.push("/")
          }}
        />
        <Row>
          <Col>
            <h1 className="mainText mt-3">
              Support
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Thank you for being a part of the GiveBee community!
            </p>
            <p>
              Please reach out and let us know how we can help: support@givebee.co
            </p>
          </Col>
        </Row>
      </Container>
    );
}

export default SupportPage;
