import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import BusinessAccountNavModule from "./BusinessAccountNavModule";

import * as pathNames from '../../constants/PathNames';
import { logout } from "../../helpers/auth";

const BusinessAccountNavMenu: React.FC<any> = (props:any) => {

  const { history, currentBusiness } = props;

  useEffect(() => {
    // Nada
  }, [props.currentBusiness]);

  return (
    <div className="ml-2 pl-2 pr-2">
      <BusinessAccountNavModule currentBusiness={currentBusiness} />
      <Row className="mt-3">
        <Col>
            <ul className="nav-ul">
              <li className="font-weight-demibold text-left">
                <a
                  href={pathNames.businessCustomersPath}
                  onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessDashboardPath); }}>
                  Dashboard
                </a>
              </li>
              <li className="font-weight-demibold text-left mt-3">
                <a
                  href={pathNames.businessCustomersPath}
                  onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessCustomersPath); }}>
                  Customers
                  </a>
              </li>
              <li className="font-weight-demibold text-left mt-3">
                <a
                  href={pathNames.businessContentPath}
                  onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessContentPath); }}>
                  Content
                </a>
              </li>
              <li className="font-weight-demibold text-left mt-3">
                <a
                  href={pathNames.businessManageRewardsPath}
                  onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessManageRewardsPath); }}>
                  Rewards
                </a>
              </li>
              <li className="font-weight-demibold text-left mt-3">
                <a
                  href={pathNames.businessManageRewardsPath}
                  onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessActivityPath); }}>
                  Activity History
                </a>
              </li>
              <li className="font-weight-demibold text-left mt-3">
                <a
                  href={pathNames.businessEditAccountPath}
                  onClick={(e:any) => { e.preventDefault(); history.push(pathNames.businessEditAccountPath); }}>
                  Account Settings
                </a>
              </li>
              <li className="font-weight-demibold text-left mt-5">
                <a
                  className={ "nav-link-gray" }
                  href="/"
                  onClick={(e:any) => { logout(); }}>
                    Log Out
                </a>
              </li>
            </ul>
        </Col>
      </Row>
    </div>
  );
}

export default BusinessAccountNavMenu;
