import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import EditRewards from "../BusinessRewards/EditRewards";

const SetRewards: React.FC<any> = ({ currentBusiness }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div>
        <Row className="account-page-title-row">
          <Col className="text-left">
            <h1>Set Rewards</h1>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}className="text-left">
            <EditRewards currentBusiness={currentBusiness} />
          </Col>
          <Col xs={12} md={4}>
            <h3>Want recommendations?</h3>
            <p>Text Us: (847) 856-9342</p>
          </Col>
        </Row>
      </div>
  );
};

export default SetRewards;