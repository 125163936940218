import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import EditRewards from "./EditRewards";

const BusinessRewardsPage: React.FC<any> = (props:any) => {

  const { currentBusiness } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div>
        <Row className="account-page-title-row">
          <Col className="text-left">
            <h1>Rewards</h1>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}className="text-left">
            <EditRewards currentBusiness={currentBusiness} />
          </Col>
        </Row>
      </div>
  );
};

export default BusinessRewardsPage;